import {request} from "../../../util/Util";
import CorkDrop from "../../../modules/CorkDrop";
import React, {Component} from "react";

class Customization extends Component {
    state = {
        custom: null,
        options: null,
        save: 0
    };

    componentWillMount() {
        this.setState({custom: this.props.item, options: this.props.item.OPTIONS});
    }

    add() {
        let {options} = this.state;

        options.push({
            NAME: "",
            PRICE: ""
        });

        this.setState({options: options});
    }

    save() {
        this.setState({save: 1});
        let saving = new Date().getTime();

        request("customization/" + this.state.custom.ID, "PATCH", {
            PAYLOAD: this.state.custom
        }, (err, payload) => {
            if (err) {
                return;
            }

            let saved = () => {
                this.setState({save: 2});
                setTimeout(() => {
                    this.setState({save: 0});
                }, 3000);
            };

            if (saving + 1000 > new Date().getTime()) {
                setTimeout(() => {
                    saved();
                }, 1000);
            } else {
                saved();
            }

            for (let option of this.state.options) {
                if (typeof option.ID === "undefined") {
                    option.ID = payload.shift();
                }
            }

        });
    }

    remove(id, i) {
        if (!window.confirm("Are you sure you want to remove this option?")) {
            return;
        }

        if (typeof id === "undefined") {
            let options = this.state.options;
            options.splice(i, 1);

            this.setState({custom: {...this.state.custom, OPTIONS: options}, options: options});
            return;
        }

        let saved = new Date().getTime();
        this.setState({save: 1});

        request("option/" + id, "DELETE", {}, (err, payload) => {
            if (err) {
                alert("ERROR");
                return;
            }

            let options = this.props.item.OPTIONS;
            let index = options.findIndex((item) => item.ID === id);
            options.splice(index, 1);

            let save = () => {
                this.setState({save: 2});

                setTimeout(() => {
                    this.setState({save: 0});
                }, 2500)
            };

            if (saved + 1000 > new Date().getTime()) {
                setTimeout(() => {
                    save();
                }, 1000);
            } else {
                save();
            }

            this.setState({custom: {...this.state.custom, OPTIONS: options}, options: options});
        });
    }

    setDefault(id) {
        if (typeof id === "undefined") {
            alert("Please click save before setting default");
            return;
        }

        request("customization/" + this.state.custom.ID + "/option/" + id, "POST", {}, (err, payload) => {
            if (err) {
                alert("error");
                return;
            }

            this.setState({custom: {...this.state.custom, DEFAULT_OPTION: id}});
        });
    }

    render() {
        let {item} = this.props;
        let {custom, options} = this.state;

        if (custom === null) {
            return <div/>
        }
        let Options = item.OPTIONS.map((option, i) => {
            return (
                <div className="row g-mb-20">
                    <div className="col-md-6">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Option Name"
                                onChange={(e) => {
                                    options.splice(i, 1, {...options[i], NAME: e.target.value});
                                    this.setState({options: options})
                                }}
                                value={options[i].NAME}/>
                            <a className="u-link-v5 g-absolute-centered--y g-right-15 g-font-style-normal g-color-secondary g-color-gray-dark-v11--hover show-cursor"
                               onClick={this.setDefault.bind(this, options[i].ID)}>
                                <i className="hs-admin-check" title="Confirmed"/>
                                <span
                                    className="g-hidden-lg-down g-font-weight-300 g-font-size-default g-ml-8 show-cursor">{
                                    options[i].ID === custom.DEFAULT_OPTION ? "Default" : "Set Default"
                                }</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Price (in cents)" value={options[i].PRICE}
                                onChange={(e) => {
                                    options.splice(i, 1, {...options[i], PRICE: e.target.value});
                                    this.setState({options: options})
                                }}
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                         onClick={this.remove.bind(this, option.ID, i)}>
                        <i style={{color: "red", fontSize: 26}} className="fa fa-times show-cursor"/>
                    </div>
                </div>
            );
        });

        let save, saveIcon;
        switch (this.state.save) {
            case 0:
                save = "Save";
                saveIcon = <i className="fa fa-save g-font-size-16"/>;
                break;
            case 1:
                saveIcon = <i className="fa fa-spinner fa-spin g-font-size-16"/>;
                save = "Saving";
                break;
            case 2:
                saveIcon = <i className="hs-admin-check g-font-size-16"/>;
                save = "Saved";
                break;
        }


        return (
            <div className="g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md"
                 style={{marginBottom: 10}}>
                <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                    <div className="row g-mb-20">
                        <div className="col-md-6">
                            <label className="g-mb-10">Name</label>
                            <div className="form-group g-pos-rel mb-0">
                                <input
                                    className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                    type="text" placeholder="Customization Name" value={custom.NAME}
                                    onChange={(e) => this.setState({custom: {...custom, NAME: e.target.value}})}
                                    style={{
                                        backgroundImage: 'url("data:image/png',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundAttachment: 'scroll',
                                        backgroundSize: '16px 18px',
                                        backgroundPosition: '98% 50%'
                                    }}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="g-mb-10">Type</label>
                            <div className="form-group g-pos-rel mb-0">
                                <CorkDrop items={[
                                    {NAME: "Default", VALUE: 0},
                                    {NAME: "Quantity", VALUE: 1},
                                    {NAME: "Multi-Select", VALUE: 2},
                                    {NAME: "Multi-Select w/ Quality", VALUE: 3}
                                ]} value={custom.TYPE} button={false}
                                          onChange={(val) => {
                                              this.setState({custom: {...custom, TYPE: parseInt(val)}})
                                          }}/>
                            </div>
                        </div>
                    </div>

                    <div className="form-group d-flex align-items-center justify-content-between g-mb-20 show-cursor"
                         onClick={(e) => this.setState({custom: {...custom, REQUIRED: !custom.REQUIRED}})}
                    >
                        <span style={{marginLeft: 5}}>Required</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox"
                                   checked={custom.REQUIRED}
                            />

                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <label className="g-mb-10">Options</label>
                    {Options}
                    <div className="d-flex g-mb-20">
                        <a className="u-link-v5 d-flex align-items-center g-color-secondary show-cursor"
                           onClick={this.save.bind(this)}>
                            {saveIcon}
                            <span className="g-ml-8">{save}</span>
                        </a>
                        <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor"
                           onClick={this.add.bind(this)}>
                                <span
                                    className="u-badge-v2--xl g-pos-rel g-transform-origin--top-left g-bg-lightblue-v3 g-font-size-10 g-color-white">
                                    <i className="hs-admin-plus g-absolute-centered"/>
                                </span>
                            <span className="g-ml-15">Add Option</span>
                        </a>
                        <a className="u-link-v5 d-flex align-items-center g-color-secondary g-ml-30 show-cursor"
                           onClick={this.props.remove}>
                            <i style={{color: "#ff0066"}} className="fa fa-trash g-font-size-16"/>
                            <span className="g-ml-15" style={{color: "#ff0066"}}>Delete Customization</span>
                        </a>
                    </div>
                </form>
            </div>
        )
    }
}

export default Customization;