import React, {Component} from 'react'
import Table from "../../modules/Table";
import {COLORS, request, toDollars} from "../../util/Util";
import Fancy from "./../../modules/Fancy";
import CreateEmployeeModal from "./modals/CreateCompanyModal";
import CorkButton from "../../modules/CorkButton";
import CreateLocationModal from "./modals/CreateLocationModal";
import moment from "moment"

class LocationsScreen extends Component {
    state = {
        name: ""
    };

    createLocation() {
        let {name} = this.state;

        request("/admin/location", "POST", {NAME: name}).then((payload) => {
            this.props.updateLocations([...this.props.partner.locations, {
                ID: payload.ID,
                TOKEN: payload.TOKEN,
                NAME: payload.NAME
            }]);

            this.setState({name: ""});
        });
    }

    render() {
        let {locations, companies, orders} = this.props.partner;

        let companyIds = companies.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        const DAY_START = moment().startOf("day").toDate().getTime();
        const WEEK_AGO = moment().startOf("day").subtract(7, "days").toDate().getTime();
        const MONTH_AGO = moment().startOf("day").subtract(31, "days").toDate().getTime();
        let Data = locations.map((item) => {
            let locationOrders = orders.filter((_item) => {
                return _item.LOCATION_ID === item.ID
            });

            return {
                ID: item.ID,
                NAME: item.NAME,
                COMPANY: item.COMPANY_ID ? companyIds[item.COMPANY_ID].NAME : "Unknown",
                DAILY: locationOrders.filter((_item) => _item.DATE_SENT > DAY_START).length,
                WEEKLY: locationOrders.filter((_item) => _item.DATE_SENT > WEEK_AGO).length,
                MONTHLY: locationOrders.filter((_item) => _item.DATE_SENT > MONTH_AGO).length,
                TOTAL: locationOrders.length
            }
        });

        let CompanyData = companies.map((item) => {
            return {
                ID: item.ID,
                NAME: item.NAME,
                LOGO: item.LOGO,
                WHITE: item.WHITE_LABEL === 1 ? "Yes" : "No"
            }
        });

        return (
            <div>
                <CreateEmployeeModal ref={(e) => this.createEmployee = e} {...this.props} />
                <CreateLocationModal ref={(e) => this.createLocation = e} {...this.props} />

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Locations & Companies</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <CorkButton onClick={() => this.createEmployee.open()}>
                                Add Company
                            </CorkButton>

                            <CorkButton marginLeft onClick={() => this.createLocation.open()}>
                                Add Location
                            </CorkButton>
                        </div>
                    </div>

                    <div>
                        <Table header={{
                            ID: {
                                NAME: "Identifier",
                                WIDTH: 3
                            },
                            NAME: {
                                NAME: "Location",
                                WIDTH: 30,
                                LINK: "/location/",
                                LINK_ID: "ID"
                            },
                            COMPANY: {
                                NAME: "Company",
                                WIDTH: 30
                            },
                            DAILY: {
                                NAME: "Daily Orders",
                                WIDTH: 10,
                            },
                            WEEKLY: {
                                NAME: "Weekly Orders",
                                WIDTH: 10,
                            },
                            MONTHLY: {
                                NAME: "Monthly Orders",
                                WIDTH: 10,
                            },
                            TOTAL: {
                                NAME: "Total Orders",
                                WIDTH: 10
                            }
                        }} sort="ID" data={Data} desc={true} title="Locations"/>
                        <Table header={{
                            ID: {
                                NAME: "Identifier",
                                WIDTH: 5
                            },
                            NAME: {
                                NAME: "Name",
                                WIDTH: 50,
                            },
                            LOGO: {
                                NAME: "Total Orders",
                                WIDTH: 10
                            },
                            WHITE: {
                                NAME: "White Labeled App",
                                WIDTH: 10,
                            }
                        }} sort="ID" data={CompanyData} desc={true} title="Companies"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default LocationsScreen;
