import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {toDollars} from "../../../util/Util";

class ItemsTab extends Component {
    render() {
        let {order} = this.props;

        let Items = order.ITEMS.map((item) => {
            return (
                <div>
                    <header style={{marginTop: 20}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            {item.NAME}
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormItem disabled={true} name="Price" value={"$" + toDollars(item.PRICE)}/>
                    <FormTextBox disabled={true} name="Customizations" value={
                        item.CUSTOMIZATIONS.map((item) => {
                            return item.KEY.NAME + ": " + item.VALUE.NAME + " "
                        }).join(", ")
                    }/>
                </div>
            );
        });


        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    {Items}
                </div>
            </div>
        )
    }
}

export default ItemsTab;