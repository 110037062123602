import React, {Component} from 'react'
import Card from "./modules/Card";
import {Chart} from "react-charts";
import {formatDate, toDollars, uploadImage} from "../../util/Util";

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const FULL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
let moment = require("moment");

const COLORS = {
    0: "green",
    1: "lightgreen",
    2: "red",
    3: "#FA8072"
};

const DAY = 1000 * 60 * 60 * 24;
class DashboardScreen extends Component {
    render() {
        let {members, orders} = this.props.partner;
        let startOfWeek = moment().startOf("isoWeek").toDate().getTime();
        let startOfDay = moment().startOf("day").toDate().getTime();

        let weeklyOrders = this.props.partner.orders.filter((item) => item.DATE_SENT > startOfWeek);
        let weeklyMembers = members.filter((item) => item.DATE_CREATED > startOfWeek);

        let weeklyTotal = 0;
        let weeklyAverage = weeklyOrders.reduce((accum, item) => {
            weeklyTotal += item.SUBTOTAL * .921 + item.TAXES;

            return accum + item.SUBTOTAL;
        }, 0) / weeklyOrders.length;

        const lastSevenStart = moment().startOf("day").subtract(7, "days").toDate().getTime();

        members = members.filter((item) => item.DATE_CREATED > lastSevenStart).slice();
        members.sort((a, b) => b.DATE_CREATED - a.DATE_CREATED);

        let WeekBack = new Date().getTime() - (1000 * 60 * 60 * 24 * 7);
        let Today = moment().startOf("day").toDate();

        let lists = [], twoWeekOrders= [], lists2 = [], twoWeekMembers = [];
        for (let i = 0; i < 7; i++) {
            let currentWindow = moment().subtract(i, "days").format("MM/DD/YY");
            let lastWindow = moment().subtract(1, "weeks").subtract(i, "days").format("MM/DD/YY");
            let currentMembers = members.filter((item) => moment(item.DATE_CREATED).format("MM/DD/YY") === currentWindow);
            let twoWeekMember = members.filter((item) => moment(item.DATE_CREATED).format("MM/DD/YY") === lastWindow);
            let currentOrders = orders.filter((item) => moment(item.DATE_SENT).format("MM/DD/YY") === currentWindow);
            let twoWeekOrder = orders.filter((item) => moment(item.DATE_SENT).format("MM/DD/YY") === lastWindow);

            lists.splice(0, 0, [FULL_DAYS[Today.getDay()], currentOrders.length]);
            lists2.splice(0, 0, [FULL_DAYS[Today.getDay()], currentMembers.length]);
            twoWeekMembers.splice(0, 0, [FULL_DAYS[Today.getDay()], twoWeekMember.length]);
            twoWeekOrders.splice(0, 0, [FULL_DAYS[Today.getDay()], twoWeekOrder.length]);
            Today = moment(Today).subtract(1, "days").toDate();
        }

        let l7o = orders.filter((order) => order.DATE_SENT > WeekBack);

        let sixDays = moment().startOf("day").subtract(6, "days").toDate().getTime();
        let todayOrders = orders.filter((order) => order.DATE_SENT > startOfDay);
        let lastWeekTodayOrders = orders.filter((order) => order.DATE_SENT > lastSevenStart && order.DATE_SENT < sixDays);

        let todayHourlys = [], lastWeekyHourlys = [];
        for (let i = 23; i >= 0; i--) {
            let hour = moment().startOf("day").add(i, "hours").format("hhA");
            let todayHourly = [], lastWeekyHourly = [];

            todayHourly = todayOrders.filter((item) => moment(item.DATE_SENT).format("hhA") === hour);
            lastWeekyHourly = lastWeekTodayOrders.filter((item) => moment(item.DATE_SENT).format("hhA") === hour);

            todayHourlys.splice(0, 0, [hour, todayHourly.length]);
            lastWeekyHourlys.splice(0, 0, [hour, lastWeekyHourly.length]);
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <Card name="Weekly Orders" value={weeklyOrders.length} icon="fa fa-shopping-cart"/>
                        <Card name="Weekly Patrons" value={weeklyMembers.length} icon="fa fa-users"/>
                        <Card name="Weekly Average" value={"$" + toDollars(weeklyAverage)} icon="fa fa-dollar-sign"/>
                        <Card name="Weekly Income" value={"$" + toDollars(weeklyTotal)} icon="fa fa-chart-line"/>
                    </div>

                    <div className="row">
                        <Card name="Last 7 Days Orders" value={l7o.length}
                              icon="fa fa-shopping-cart"/>
                        <Card name="Last 7 Days Patrons"
                              value={members.filter((member) => member.DATE_CREATED > WeekBack).length}
                              icon="fa fa-users"/>
                        <Card name="Last 7 Days Revenue"
                              value={"$" + toDollars(l7o.reduce((accum, item) => accum + item.TOTAL, 0))}
                              icon="fa fa-dollar-sign"/>
                        <Card name="Last 7 Days Income"
                              value={"$" + toDollars(l7o.reduce((accum, item) => accum + item.PROCESSING, 0))}
                              icon="fa fa-chart-line"/>
                    </div>


                    <div className="row">
                        <div className="col-xl-4">
                            {/* User Card */}
                            <div
                                className="card g-brd-gray-light-v7 text-center g-pt-40 g-pt-60--md g-mb-30">
                                <header className="g-mb-30">
                                </header>
                                <section
                                    className="row no-gutters g-brd-top g-brd-gray-light-v4">
                                    <div className="col-6 g-py-10 g-py-25--md">
                                        <strong
                                            className="d-block g-font-weight-500 g-font-size-18 g-color-black">
                                            {this.props.partner.members.length}
                                        </strong>
                                        <span
                                            className="g-font-weight-300 g-color-gray-dark-v6">Patrons</span>
                                    </div>
                                    <div
                                        className="col-6 g-brd-left--md g-brd-gray-light-v4 g-py-10 g-py-25--md">
                                        <strong
                                            className="d-block g-font-weight-500 g-font-size-18 g-color-black">
                                            {this.props.partner.orders.length}
                                        </strong>
                                        <span
                                            className="g-font-weight-300 g-color-gray-dark-v6">Orders</span>
                                    </div>
                                </section>
                            </div>
                            {/* End User Card */}
                        </div>

                        <div className="col-xl-8">
                            <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                                <header className="media g-mb-30">
                                    <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                        Hourly Comparison
                                    </h3>
                                </header>

                                <section>
                                    <Chart
                                        style={{
                                            width: "400px",
                                            height: "200px",
                                            marginBottom: 100
                                        }}
                                        data={[
                                            {
                                                label: "Today",
                                                data: todayHourlys
                                            },
                                            {
                                                label: "Last Week Today",
                                                data: lastWeekyHourlys
                                            }
                                        ]}
                                        series={{type: 'line'}}
                                        axes={[
                                            {primary: true, type: 'ordinal', position: 'bottom'},
                                            {
                                                position: 'left',
                                                type: 'linear',
                                                stacked: false,
                                                format: tick => `${tick}`
                                            },
                                        ]}
                                        primaryCursor
                                        secondaryCursor
                                        tooltip
                                    />
                                </section>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                                <header className="media g-mb-30">
                                    <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                        Weekly Stats
                                    </h3>
                                </header>

                                <section>
                                    <Chart
                                        style={{
                                            width: "400px",
                                            height: "200px",
                                            marginBottom: 100
                                        }}
                                        getSeriesStyle={(series) => {
                                            return {
                                                color: COLORS[series.index]
                                            }
                                        }}
                                        data={[
                                            {
                                                label: "Orders",
                                                data: lists
                                            },
                                            {
                                                label: "Last Orders",
                                                data: twoWeekOrders
                                            },
                                            {
                                                label: "New Patrons",
                                                data: lists2
                                            },
                                            {
                                                label: "Last Week Patrons",
                                                data: twoWeekMembers,
                                            }
                                        ]}
                                        series={{type: 'line'}}
                                        axes={[
                                            {primary: true, type: 'ordinal', position: 'bottom'},
                                            {
                                                position: 'left',
                                                type: 'linear',
                                                stacked: false,
                                                format: tick => `${tick}`
                                            },
                                        ]}
                                        primaryCursor
                                        secondaryCursor
                                        tooltip
                                    />
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + jsDate.getMinutes() + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + jsDate.getMinutes() + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }
}

export default DashboardScreen;
