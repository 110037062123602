import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {COLORS, request} from "../../../util/Util";
import FormTextBox from "../../../modules/FormTextBox";
import Double from "../../../modules/Double";

let Moment = require("moment");
let chrono = require('chrono-node');

class GeneralTab extends Component {
    state = {
        location: {}
    };

    componentDidMount() {
        this.setState({
            location: Object.keys(this.props.partner.location).reduce((dict, item) => {
                dict[item] = this.props.partner.location[item];
                return dict;
            }, {})
        });
    }

    updateInfo() {
        let {location} = this.state;

        request("location", "PATCH", location, (err) => {
            if (err) {
                alert("Error saving location");
                return;
            }

            alert("Your location has been updated");
            this.props.updateLocation(location);
        })
    }

    online() {
        request("online", "POST").then(() => {
            this.props.updateLocation({...this.props.partner.location, ENABLED: false});
            alert("You have gone online!");
        });
    }

    render() {
        let {location, hours} = this.state;

        let Online = <div />;
        if (this.props.partner.location.ENABLED === 0) {
            Online = (
                <button style={{backgroundColor: COLORS.DRIP_GOLD, marginLeft: 25}}
                        onClick={() => this.online()}
                        className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10"
                        type="submit">Go Online
                </button>
            )
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>
                        </header>
                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <FormItem name="Name" value={location.NAME}
                                  onChange={(val) => this.setState({location: {...location, NAME: val}})}/>
                        <FormItem name="Website" value={location.WEBSITE}
                                  onChange={(val) => this.setState({location: {...location, WEBSITE: val}})}/>
                        <FormTextBox name="Description" value={location.DESCRIPTION}
                                     onChange={(val) => this.setState({location: {...location, DESCRIPTION: val}})}/>
                        <FormTextBox name="Instructions" value={location.INSTRUCTIONS}
                                     onChange={(val) => this.setState({location: {...location, INSTRUCTIONS: val}})}/>
                        <FormItem name="Logo" value={location.LOGO}
                                  onChange={(val) => this.setState({location: {...location, LOGO: val}})}/>
                        <FormItem name="Tax Rate (out of 100)" value={location.TAX_RATE}
                                  onChange={(val) => this.setState({location: {...location, TAX_RATE: val}})}/>

                        <header style={{marginTop: 40}}>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                Location
                            </h2>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                        <FormItem name="Address" value={location.ADDRESS}
                                  onChange={(val) => this.setState({location: {...location, ADDRESS: val}})}/>
                        <FormItem name="City" value={location.CITY}
                                  onChange={(val) => this.setState({location: {...location, CITY: val}})}/>
                        <FormItem name="Zipcode" value={location.ZIPCODE}
                                  onChange={(val) => this.setState({location: {...location, ZIPCODE: val}})}/>
                        <FormItem name="State" value={location.STATE}
                                  onChange={(val) => this.setState({location: {...location, STATE: val}})}/>
                        <FormItem name="Latitude" value={location.LATITUDE}
                                  onChange={(val) => this.setState({location: {...location, LATITUDE: val}})}/>
                        <FormItem name="Longitude" value={location.LONGITUDE}
                                  onChange={(val) => this.setState({location: {...location, LONGITUDE: val}})}/>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <div>
                            <button style={{backgroundColor: "#326489"}}
                                    onClick={() => this.updateInfo()}
                                    className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10"
                                    type="submit">Save Changes
                            </button>

                            {Online}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default GeneralTab;