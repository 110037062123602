import {
    ADD_BACKGROUND_ORDER,
    ADD_ERROR,
    REMOVE_ERROR, REMOVE_POST,
    UPDATE_ANNOUNCEMENTS,
    UPDATE_BAR_BEVERAGES,
    UPDATE_BEVERAGES,
    UPDATE_CATEGORIES, UPDATE_COMPANIES, UPDATE_FOCUS,
    UPDATE_HOURS,
    UPDATE_LOCATION,
    UPDATE_NEWS,
    UPDATE_ORDERS,
    UPDATE_OVERSEER,
    UPDATE_PARTNER,
    UPDATE_PARTNERS, UPDATE_PATRONS,
    UPDATE_POST,
    UPDATE_PRESETS,
    UPDATE_PRODUCTS,
    UPDATE_PUSHES,
    UPDATE_REPS,
    UPDATE_SECTIONS
} from "../actions/index";

import moment from "moment";
let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

const defaultState = {
    locations: [],
    drinks: [],
    members: [],
    news: [],
    pushes: [],
    views: [],
    clicks: [],
    share: 0,
    newOrders: 0,
    focused: true,
    letters: [],
    sections: [],
    payouts: [],
    announcements: [],
    products: [],
    categories: [],
    orders: [],
    partners: [],
    reps: [],
    tips: [],
    deposits: [],
    referrals: [],
    demos: [],
    tickets: [],
    marketing: [],
    patrons: [],
    redeems: [],
    companies: [],
    reporting: {
        start: moment().startOf("isoWeek").toDate().getTime(),
        end: moment().endOf("isoWeek").toDate().getTime(),
        frame: 1000 * 60 * 60 * 24 * 7,
        locations: [],
        data: {orders: [], products: [], categories: [], drawers: []},
        day: null,
        last: ""
    }
};

let newState = cloneObject(defaultState);

export default function (state = newState, action) {
    switch (action.type) {
        case UPDATE_PARTNER:
            return {
                ...state,
                ...action.payload
            };
        case UPDATE_OVERSEER:
            newState = cloneObject(state);

            newState.admins = action.payload;
            return newState;
        case UPDATE_LOCATION:
            return {...state, locations: action.payload};
        case UPDATE_BEVERAGES:
            return {...state, beverages: action.payload};
        case UPDATE_NEWS:
            return {...state, news: action.payload};
        case UPDATE_PUSHES:
            return {...state, pushes: action.payload};
        case UPDATE_BAR_BEVERAGES:
            return {...state, barBeverages: action.payload};
        case UPDATE_SECTIONS:
            return {...state, sections: action.payload};
        case UPDATE_ANNOUNCEMENTS:
            return {...state, announcements: action.payload};
        case UPDATE_PRODUCTS:
            return {...state, products: action.payload};
        case UPDATE_CATEGORIES:
            return {...state, categories: action.payload};
        case UPDATE_ORDERS:
            return {...state, orders: action.payload};
        case UPDATE_HOURS:
            return {...state, hours: action.payload};
        case UPDATE_PRESETS:
            return {...state, presets: action.payload};
        case UPDATE_PARTNERS:
            return {...state, partners: action.payload};
        case UPDATE_REPS:
            return {...state, reps: action.payload};
        case UPDATE_PATRONS:
            return {...state, patrons: action.payload};
        case UPDATE_COMPANIES:
            return {...state, companies: action.payload};
        case UPDATE_FOCUS:
            let newState = {...state, focused: action.payload};

            if (newState.focused === true) {
                newState.newOrders = 0;
            }

            return newState;
        case ADD_BACKGROUND_ORDER:
            return {...state, newOrders: state.newOrders + 1};
        case ADD_ERROR:
            return {...state, announcements: [...state.announcements, action.payload]};
        case REMOVE_ERROR:
            let announcements = state.announcements;
            let index = announcements.findIndex((item) => item.ID === action.payload);

            if (index === -1) {
                return state;
            }

            announcements.splice(index, 1);
            return {...state, announcements: announcements};
        case UPDATE_POST:
            let _news = state.news;
            let nIndex = _news.findIndex((item) => item.ID === action.payload.ID);

            if (nIndex === -1) {
                return state;
            }

            _news.splice(nIndex, 1, action.payload);
            return {...state, news: _news};
        case REMOVE_POST:
            let rNews = state.news;
            let rnIndex = rNews.findIndex((item) => item.ID === action.payload);

            if (rnIndex === -1) {
                return state;
            }

            rNews.splice(rnIndex, 1);
            return {...state, news: rNews};
        default:
            return state
    }
}
