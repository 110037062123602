import React, {Component} from "react"
import Fancy from "../../../modules/Fancy";

import {FancyText, FancyDate, FancySelect} from "corky"
import {COLORS, request} from "../../../util/Util";

const FIELDS = {
    NAME: "location-name",
    COMPANY: "location-company",
};

class CreateLocationModal extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }

    create() {
        let form = this.form;

        let valid = true;
        for (let company of this.companyList) {
            if (company === null) {
                continue;
            }

            if (!company.isValid()) {
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        let {locations} = this.props.partner;
        let name = form[FIELDS.NAME], company = form[FIELDS.COMPANY].value;
        request("/admin/location", "POST", {NAME: name, COMPANY_ID: company}).then((payload) => {
            this.fancy.close().then(() => {
                this.props.updateLocations([...locations, {
                    ID: payload.ID,
                    COMPANY_ID: company,
                    TOKEN: payload.TOKEN,
                    NAME: payload.NAME
                }]);
            })
        });
    }

    open() {
        this.fancy.open()
    }

    renderOptions() {
        let {companies} = this.props.partner;

        return companies.map((item) => ({
            label: item.NAME,
            value: item.ID
        }));
    }

    render() {
        this.companyList = [];
        let form = this.form;

        return (
            <Fancy name="Create Location" onClick={this.create.bind(this)}
                   cancelText="Cancel" ref={(e) => this.fancy = e} buttonText="Create"
            >
                <form>
                    <div className="g-mb-20">
                        <FancyText form={form} id={FIELDS.NAME} type={FancyText.TYPES.TEXT} required={true}
                                   ref={(e) => this.companyList.push(e)} label="Company Name"/>
                    </div>

                    <div className="g-mb-20">
                        <FancySelect options={this.renderOptions()} form={form} id={FIELDS.COMPANY} required={true}
                                   ref={(e) => this.companyList.push(e)} label="Company" />
                    </div>
                </form>
            </Fancy>
        )
    }
}

export default CreateLocationModal;