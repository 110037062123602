export const UPDATE_REDEEM = 'UPDATE_REDEEM';


// Partner Stuff
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_OVERSEER = 'UPDATE_OVERSEER';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_BEVERAGES = 'UPDATE_BEVERAGES';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const UPDATE_PUSHES = 'UPDATE_PUSHES';
export const UPDATE_BAR_BEVERAGES = 'UPDATE_BAR_BEVERAGES';
export const UPDATE_SECTIONS = 'UPDATE_SECTIONS';
export const UPDATE_ANNOUNCEMENTS = 'UPDATE_ANNOUNCEMENTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_HOURS = 'UPDATE_HOURS';
export const UPDATE_PRESETS = 'UPDATE_PRESETS';
export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const UPDATE_REPS = 'UPDATE_REPS';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const UPDATE_POST = 'UPDATE_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const ADD_BACKGROUND_ORDER = 'ADD_BACKGROUND_ORDER';
export const UPDATE_FOCUS = 'UPDATE_FOCUS';
export const UPDATE_PATRONS = 'UPDATE_PATRONS';
export const UPDATE_COMPANIES = 'UPDATE_COMPANIES';

export function updateRedeem(credentials) {
    return {
        type: UPDATE_REDEEM,
        payload: credentials
    }
}

export function updatePartner(credentials) {
    return {
        type: UPDATE_PARTNER,
        payload: credentials
    }
}

export function updateOverseer(credentials) {
    return {
        type: UPDATE_OVERSEER,
        payload: credentials
    }
}

export function updateLocations(credentials) {
    return {
        type: UPDATE_LOCATION,
        payload: credentials
    }
}

export function updateBeverages(credentials) {
    return {
        type: UPDATE_BEVERAGES,
        payload: credentials
    }
}

export function updateNews(credentials) {
    return {
        type: UPDATE_NEWS,
        payload: credentials
    }
}

export function updatePushes(credentials) {
    return {
        type: UPDATE_PUSHES,
        payload: credentials
    }
}

export function updateBarBeverages(credentials) {
    return {
        type: UPDATE_BAR_BEVERAGES,
        payload: credentials
    }
}

export function updateSections(credentials) {
    return {
        type: UPDATE_SECTIONS,
        payload: credentials
    }
}

export function updateAnnouncements(credentials) {
    return {
        type: UPDATE_ANNOUNCEMENTS,
        payload: credentials
    }
}

export function updateProducts(credentials) {
    return {
        type: UPDATE_PRODUCTS,
        payload: credentials
    }
}

export function updateCategories(credentials) {
    return {
        type: UPDATE_CATEGORIES,
        payload: credentials
    }
}

export function updateOrders(credentials) {
    return {
        type: UPDATE_ORDERS,
        payload: credentials
    }
}

export function updateHours(credentials) {
    return {
        type: UPDATE_HOURS,
        payload: credentials
    }
}

export function updatePresets(credentials) {
    return {
        type: UPDATE_PRESETS,
        payload: credentials
    }
}

export function updatePartners(credentials) {
    return {
        type: UPDATE_PARTNERS,
        payload: credentials
    }
}

export function updateReps(credentials) {
    return {
        type: UPDATE_REPS,
        payload: credentials
    }
}

export function addError(credentials) {
    return {
        type: ADD_ERROR,
        payload: credentials
    }
}

export function removeError(credentials) {
    return {
        type: REMOVE_ERROR,
        payload: credentials
    }
}

export function updatePost(credentials) {
    return {
        type: UPDATE_POST,
        payload: credentials
    }
}

export function removePost(credentials) {
    return {
        type: REMOVE_POST,
        payload: credentials
    }
}

export function addBackgroundOrder(credentials) {
    return {
        type: ADD_BACKGROUND_ORDER,
        payload: credentials
    }
}

export function updateFocus(credentials) {
    return {
        type: UPDATE_FOCUS,
        payload: credentials
    }
}

export function updatePatrons(credentials) {
    return {
        type: UPDATE_PATRONS,
        payload: credentials
    }
}

export function updateCompanies(credentials) {
    return {
        type: UPDATE_COMPANIES,
        payload: credentials
    }
}

export const UPDATE_REPORTING = 'UPDATE_REPORTING';
export function updateReporting(credentials) {
    return {
        type: UPDATE_REPORTING,
        payload: credentials
    }
}