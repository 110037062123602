import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {COLORS, request} from "../../../util/Util";
import Fancy from "../../../modules/Fancy";
import CorkDrop from "../../../modules/CorkDrop";
import Customization from "../modules/Customization";

class CustomizationsTab extends Component {
    state = {
        name: "",
        preset: -1,
        type: 0,
        required: false
    };

    componentDidMount() {
        if (this.props.partner.presets.length > 0) {
            this.setState({preset: this.props.partner.presets[0].ID});
        }
    }

    remove(id) {
        const {ID} = this.props.match.params;
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        request("customization/" + id, "DELETE", {},  (err) => {
            if (err) {
                alert("ERROR SAVING ON SERVER");
                return;
            }

            let products = this.props.partner.products;
            let productIndex = products.findIndex((item) => item.ID === parseInt(ID));
            let customizations = products[productIndex].CUSTOMIZATIONS;
            let index = customizations.findIndex((item) => item.ID === id);

            customizations.splice(index, 1);

            products.splice(productIndex, 1, {
                ...products[productIndex],
                CUSTOMIZATIONS: customizations
            });

            this.props.updateProducts(products);
        });
    }

    add() {
        const {ID} = this.props.match.params;
        let {name, type, required} = this.state;

        request("product/" + ID + "/customization", "POST", {NAME: name, TYPE: type, REQUIRED: required}, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            let products = this.props.partner.products;
            let index = products.findIndex((item) => item.ID === parseInt(ID));

            products.splice(index, 1, {
                ...products[index],
                CUSTOMIZATIONS: [...products[index].CUSTOMIZATIONS, {
                    NAME: name,
                    ID: id,
                    OPTIONS: [],
                    TYPE: type,
                    REQUIRED: required
                }]
            });

            this.fancy.close().then(() => {
                this.props.updateProducts(products);
            });
        });
    }

    addPreset() {
        const {ID} = this.props.match.params;
        let {preset} = this.state;

        request("preset/" + preset + "/product/" + ID, "POST", {}, (err, payload) => {
            if (err) {
                alert("ERRPR");
                return;
            }

            let products = this.props.partner.products;
            let index = products.findIndex((item) => item.ID === parseInt(ID));

            products.splice(index, 1, {
                ...products[index],
                CUSTOMIZATIONS: [...products[index].CUSTOMIZATIONS, payload]
            });


            this.fancy.close().then(() => {
                this.props.updateProducts(products);
            });
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let {name, preset, type, required} = this.state;

        let product = this.props.partner.products.find((item) => parseInt(ID) === item.ID);

        let Customizations = product.CUSTOMIZATIONS.map((item) => {
            return <Customization item={item} id={ID} {...this.props} remove={() => this.remove(item.ID)}/>
        });

        return (
            <div className="col-md-9">
                <Fancy name="Create Customization" ref={(e) => this.fancy = e}
                       onClick={this.add.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={name} onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}
                               aria-invalid="false"/>

                        <div className="g-mb-20">
                            <label className="g-mb-10" htmlFor="#bio">Type</label>

                            <CorkDrop items={[
                                {NAME: "Default", VALUE: 0},
                                {NAME: "Quantity", VALUE: 1},
                                {NAME: "Multi-Select", VALUE: 2},
                                {NAME: "Multi-Select w/ Quality", VALUE: 3}
                            ]} value={type} onChange={(type) => this.setState({type})}/>
                        </div>

                        <div className="form-group d-flex align-items-center justify-content-between g-mb-20">
                            <span>Required</span>
                            <label className="u-check mb-0 g-ml-10">
                                <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                       type="checkbox" onClick={(e) => this.setState({required: !required})}
                                       checked={required}
                                />

                                <div
                                    className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                    <i className="fa"/>
                                </div>
                            </label>
                        </div>
                    </div>
                </Fancy>

                <Fancy name="Add Preset" ref={(e) => this.presetFancy = e}
                       onClick={this.addPreset.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <CorkDrop items={this.props.partner.presets.map((item) => {
                            return {VALUE: item.ID, NAME: item.NAME}
                        })} value={preset} onChange={(preset) => this.setState({preset})}/>
                    </div>
                </Fancy>

                <div style={{float: "right", margin: 15}}>
                    <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold"}} onClick={() => {
                        this.fancy.show();
                    }}>
                        + Add Customization
                    </a>

                    <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold", marginLeft: 10}}
                       onClick={() => {
                           this.presetFancy.open();
                       }}>
                        + Add Preset
                    </a>
                </div>

                <div style={{marginTop: 50}}>
                    {Customizations}
                </div>
            </div>
        )
    }
}

export default CustomizationsTab;