import React, {Component} from 'react'
import Table from "../../modules/Table";
import {COLORS, request, toDollars} from "../../util/Util";
import Fancy from "./../../modules/Fancy";
import moment from "moment";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

class DepositsScreen extends Component {
    state = {
        name: ""
    };


    render() {
        let {name} = this.state;

        let locations = this.props.partner.locations.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let Data = this.props.partner.marketing.map((item) => {
            let account = this.props.partner.members.find((_item) => {
                if (_item.ID === 1) {
                    console.log(_item.PHONE === "1" + item.PHONE)
                }

                return _item.PHONE === "1" + item.PHONE
            });

            return {
                ...item,
                ID: item.ID,
                ACCOUNT: account ? account.FULL_NAME : "",
                NUMBER: phoneUtil.formatInOriginalFormat(phoneUtil.parseAndKeepRawInput(item.PHONE, 'US'), 'US'),
                ACCOUNT_ID: account ? account.ID : 0,
                DATE: moment(item.DATE_SENT).format("hh:mm a MM/DD/YY"),
                LOCATION: locations[item.LOCATION_ID].NAME,
                DATE_CREATED: account ? moment(account.DATE_CREATED).format("hh:mm a MM/DD/YY") : ""
            }
        });

        return (
            <div>
                <Table header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 3
                    },
                    NUMBER: {
                        NAME: "Phone Number",
                        WIDTH: 20,
                    },
                    LOCATION: {
                        NAME: "Location",
                        WIDTH: 20,
                        LINK: "/location/",
                        LINK_ID: "LOCATION_ID"
                    },
                    DATE: {
                        NAME: "Date Sent",
                        WIDTH: 20,
                    },
                    ACCOUNT: {
                        NAME: "Account",
                        WIDTH: 20,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    DATE_CREATED: {
                        NAME: "Account Created",
                        WIDTH: 20,
                    }
                }} sort="ID" data={Data} desc={true} title="Marketing"/>
            </div>
        );
    }
}

export default DepositsScreen;
