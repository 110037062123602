import React, {Component} from 'react'
import Double from "../../../modules/Double";
import {COLORS, getURL, request} from "../../../util/Util";

let moment = require("moment");
let chrono = require('chrono-node');

class PayoutTab extends Component {
    open() {
        if (this.props.partner.location.STRIPE_ID === null) {
            request("stripe/connect", "GET").then((data) => {
                window.open(data);
            });
        } else {
            request("stripe/dashboard", "GET").then((data) => {
                window.open(data);
            });
        }
    }
    render() {
        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Payouts
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>


                    <div>
                        <button style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                                onClick={this.open.bind(this)}
                                className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10"
                                type="submit">{this.props.partner.location.STRIPE_ID === null ? "Add Payout Account" : "View Payouts"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PayoutTab;