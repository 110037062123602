import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {COLORS, request} from "../../../util/Util";

let moment = require("moment");

class AlterTab extends Component {
    state = {user: {FULL_NAME: "Loading", EMAIL: "Loading", BALANCE: "Loading", PHONE: "Loading", POINTS: "Loading"}};

    componentWillMount() {
        const {ID} = this.props.match.params;

        let member = this.props.partner.members.find((item) => {
            return item.ID === parseInt(ID);
        });

        this.setState({user: JSON.parse(JSON.stringify(member))})
    }

    updateBalance() {
        let {user} = this.state;

        request(`admin/account/${user.ID}/balance`, "PATCH", {AMOUNT: user.BALANCE}).then(() => {
            alert("done. reload cuz lazy");
        }).catch(() => {
            alert("did her wrong");
        })
    }

    updatePoints() {
        let {user} = this.state;

        request(`admin/account/${user.ID}/points`, "PATCH", {AMOUNT: user.POINTS}).then(() => {
            alert("done. reload cuz lazy");
        }).catch(() => {
            alert("did her wrong");
        })
    }

    render() {
        let {user} = this.state;

        return (
            <div className="col-md-9">
                <div className="g-pos-rel h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-30--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">Account
                            Info</h2>
                    </header>
                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-mt-25--md g-mb-30--md"/>
                    <h4 className="g-font-weight-300 g-font-size-default g-color-black g-mb-10">Name</h4>
                    <div className="row">
                        <div className="col-md g-mb-10">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                type="text" value={user.FULL_NAME}
                                onChange={(e) => this.setState({user: {...user, FULL_NAME: e.target.value}})} aria-invalid="false"/>
                        </div>
                        <div className="col-md-auto g-mb-10">
                            <a style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                               className="btn btn-md btn-xl--md u-btn-secondary g-width-145 g-font-size-default--md g-py-12"
                               onClick={() => {

                               }}>Update</a>
                        </div>
                    </div>
                    <h4 className="g-font-weight-300 g-font-size-default g-color-black g-mb-10">Email</h4>
                    <div className="row">
                        <div className="col-md g-mb-10">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                type="text" value={user.EMAIL}
                                onChange={(e) => this.setState({user: {...user, EMAIL: e.target.value}})} aria-invalid="false"/>
                        </div>
                        <div className="col-md-auto g-mb-10">
                            <a style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                               className="btn btn-md btn-xl--md u-btn-secondary g-width-145 g-font-size-default--md g-py-12"
                               onClick={() => {

                               }}>Update</a>
                        </div>
                    </div>
                    <h4 className="g-font-weight-300 g-font-size-default g-color-black g-mb-10">Phone</h4>
                    <div className="row">
                        <div className="col-md g-mb-10">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                type="text" value={user.PHONE}
                                onChange={(e) => this.setState({user: {...user, PHONE: e.target.value}})} aria-invalid="false"/>
                        </div>
                        <div className="col-md-auto g-mb-10">
                            <a style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                               className="btn btn-md btn-xl--md u-btn-secondary g-width-145 g-font-size-default--md g-py-12"
                               href="#!">Update</a>
                        </div>
                    </div>
                    <header style={{marginTop: 25}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Account Balance
                        </h2>
                    </header>
                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-mt-25--md g-mb-30--md"/>
                    <h4 className="g-font-weight-300 g-font-size-default g-color-black g-mb-10">Balance</h4>
                    <div className="row">
                        <div className="col-md g-mb-10">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                type="text" value={user.BALANCE}
                                onChange={(e) => this.setState({user: {...user, BALANCE: e.target.value}})} aria-invalid="false"/>
                        </div>
                        <div className="col-md-auto g-mb-10">
                            <a style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                               className="btn btn-md btn-xl--md u-btn-secondary g-width-145 g-font-size-default--md g-py-12"
                               onClick={this.updateBalance.bind(this)}>Update</a>
                        </div>
                    </div>
                    <h4 className="g-font-weight-300 g-font-size-default g-color-black g-mb-10">Points</h4>
                    <div className="row">
                        <div className="col-md g-mb-10">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                type="text" value={user.POINTS}
                                onChange={(e) => this.setState({user: {...user, POINTS: e.target.value}})} aria-invalid="false"/>
                        </div>
                        <div className="col-md-auto g-mb-10">
                            <a style={{backgroundColor: COLORS.DRIP_GOLD, color: "white"}}
                               className="btn btn-md btn-xl--md u-btn-secondary g-width-145 g-font-size-default--md g-py-12"
                               onClick={this.updatePoints.bind(this)}>Update</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default AlterTab;