import React, {Component} from 'react'
import Table from "../../modules/Table";
import {COLORS, request, toDollars} from "../../util/Util";
import Fancy from "./../../modules/Fancy";
import moment from "moment";

class DepositsScreen extends Component {
    state = {
        name: ""
    };


    render() {
        let {name} = this.state;

        let accounts = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});


        let Data = this.props.partner.referrals.map((item) => {
            let user = this.props.partner.members.find((_item) => item.PHONE === _item.PHONE);

            return {
                ...item,
                ID: item.ID,
                NAME: accounts[item.ACCOUNT_ID].FULL_NAME,
                AMOUNT: item.AMOUNT_REWARDED,
                ACCOUNT: user ? user.FULL_NAME : "",
                USER_ID: user ? user.ID : "",
                DATE: moment(item.DATE_REFERRED).format("hh:mm a MM/DD/YY")
            }
        });

        return (
            <div>
                <Table header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 3
                    },
                    NAME: {
                        NAME: "Account",
                        WIDTH: 40,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    PHONE: {
                        NAME: "Phone of Sent",
                        WIDTH: 20
                    },
                    ACCOUNT: {
                        NAME: "Created Account",
                        WIDTH: 20,
                        LINK: "/patron/",
                        LINK_ID: "USER_ID"
                    },
                    AMOUNT: {
                        NAME: "Amount Rewarded",
                        WIDTH: 10,
                    },
                    DATE: {
                        NAME: "Date Sent",
                        WIDTH: 20,
                    }
                }} sort="ID" data={Data} desc={true} title="Deposits"/>
            </div>
        );
    }
}

export default DepositsScreen;
