import React, {Component} from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import Navbar from './modules/Navbar'
import Header from './modules/Header'
import Bar from "./components/dashboard/DashboardScreen";
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from './actions/index'
import {getShortURL, request} from './util/Util'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, combineReducers} from 'redux'
import userReducers from './reducers/user'
import partnerReducers from './reducers/partner'
import io from 'socket.io-client';
import {BounceLoader, GridLoader, HashLoader, PacmanLoader, RingLoader} from "react-spinners";

import {
    withRouter
} from 'react-router-dom'
import LoginScreen from "./components/login/LoginScreen";
import FourZeroFour from "./components/FourZeroFour";
import ProductsScreen from "./components/products/ProductsScreen";
import ProductScreen from "./components/products/ProductScreen";
import FeedScreen from "./components/feed/FeedScreen";
import ShopScreen from "./components/shop/ShopScreen";
import PatronsScreen from "./components/patrons/PatronsScreen";
import PatronScreen from "./components/patrons/PatronScreen";
import OrdersScreen from "./components/orders/OrdersScreen";
import OrderScreen from "./components/orders/OrderScreen";
import LocationsScreen from "./components/locations/LocationsScreen";
import LocationScreen from "./components/locations/LocationScreen";
import PostScreen from "./components/news/PostScreen";
import NewsScreen from "./components/news/NewsScreen";
import Announcements from "./modules/Announcements";
import PushScreen from "./components/push/PushScreen";
import DepositScreen from "./components/tracking/DepositsScreen"
import ReferralsScreen from "./components/tracking/ReferralsScreen"
import MarketingScreen from "./components/tracking/MarketingScreen"
import TicketsScreen from "./components/support/tickets/TicketsScreen"
import TicketScreen from "./components/support/tickets/TicketScreen"
import DemoScreen from "./components/support/DemosScreen"

import DateRangePicker from 'react-bootstrap-daterangepicker'
import Helmet from "react-helmet"

import RedeemsScreen from "./components/rewards/RedeemsScreen";
import SalesSummary from "./components/reports/SalesSummary";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PartnerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {logged: false};
    }


    onFocus() {
        if (this.props.partner.focused === false) {
            this.props.updateFocus(true);
        }
    }

    onBlur() {
        if (this.props.partner.focused === true) {
            this.props.updateFocus(false);
        }
    }

    componentWillMount() {
        window.addEventListener("focus", this.onFocus.bind(this));
        window.addEventListener("blur", this.onBlur.bind(this));

        request('admin/valid', 'GET', null, (err, payload) => {
            if (err) {
                this.props.history.push('/login');
                return;
            }

            this.setState({logged: true});
            this.props.updatePartner(payload);

            let socket = io.connect(getShortURL(), {
                query: 'b64=1'
            });

            if (this.props.partner.focused === false) {
                this.props.addBackgroundOrder();
            }

            let token = window.localStorage['TOKEN'];
            socket.on("connect", () => {
                socket.emit("admin", {
                    TOKEN: token
                });
            });

            socket.on('checkout', (data) => {
                if (!data.NAME) {
                    data.NAME = null
                }

                this.props.updateOrders([...this.props.partner.orders, data]);
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus.bind(this))
        window.removeEventListener("blur", this.onBlur.bind(this))
    }

    render() {
        if (!this.state.logged) {
            return <LoadingScreen {...this.props}/>
        }

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{this.props.partner.newOrders > 0 ? `(${this.props.partner.newOrders})` : ""}Drip Admin</title>
                </Helmet>

                    {/* Header */}
                <Header {...this.props} />
                {/* End Header */}
                <main className="container-fluid px-0 g-pt-65">
                    <div className="row no-gutters g-pos-rel g-overflow-x-hidden">
                        {/* Sidebar Nav */}
                        <Navbar {...this.props} />

                        <Announcements/>

                        <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                            <Switch>
                                <Route exact path='/' component={() => <Bar {...this.props} />}/>
                                <Route path='/order/:ID' component={OrderScreen}/>
                                <Route path='/patron/:ID' component={PatronScreen}/>
                                <Route path='/product/:ID' component={ProductScreen}/>
                                <Route path='/location/:ID' component={LocationScreen}/>
                                <Route path='/shop' component={() => <ShopScreen {...this.props} />}/>
                                <Route exact path='/feed' component={() => <FeedScreen {...this.props} />}/>
                                <Route exact path='/products' component={() => <ProductsScreen {...this.props} />}/>
                                <Route exact path='/locations' component={() => <LocationsScreen {...this.props} />}/>
                                <Route exact path='/patrons' component={() => <PatronsScreen {...this.props} />}/>
                                <Route exact path='/orders' component={() => <OrdersScreen {...this.props} />}/>
                                <Route exact path='/redeems' component={() => <RedeemsScreen {...this.props} />}/>

                                <Route exact path='/demos' component={() => <DemoScreen {...this.props} />}/>
                                <Route exact path='/tickets' component={() => <TicketsScreen {...this.props} />}/>
                                <Route path='/ticket/:ID' component={TicketScreen}/>

                                <Route exact path='/deposits' component={() => <DepositScreen {...this.props} />}/>
                                <Route exact path='/referrals' component={() => <ReferralsScreen {...this.props} />}/>
                                <Route exact path='/marketing' component={() => <MarketingScreen {...this.props} />}/>

                                <Route exact path='/push' component={() => <PushScreen {...this.props} />}/>
                                <Route path='/post/:ID' component={PostScreen}/>
                                <Route exact path='/news' component={() => <NewsScreen {...this.props} />}/>
                                <Route exact path='/report/summary/orders'
                                       component={() => <SalesSummary picker={DateRangePicker}
                                                                            data={this.props.partner.orders}/>
                                       }/>
                                {/*<Route exact path='/report/summary/products'*/}
                                {/*       component={() => <ProductSummaryReport picker={DateRangePicker}*/}
                                {/*                                              data={this.props.partner.products}/>*/}
                                {/*       }/>*/}
                                {/*<Route exact path='/report/summary/sales'*/}
                                {/*       component={() => <SalesSummaryReport picker={DateRangePicker}*/}
                                {/*                                            tips={this.props.partner.tips}*/}
                                {/*                                            data={this.props.partner.orders}/>*/}
                                {/*       }/>*/}
                                <Redirect from='*' to='/404'/>
                            </Switch>

                            {/* Footer */}
                            <footer id="footer"
                                    className="u-footer--bottom-sticky g-bg-white g-color-gray-dark-v6 g-brd-top g-brd-gray-light-v7 g-pa-20">
                                <div className="row align-items-center">
                                    {/* Footer Nav */}
                                    <div className="col-md-4 g-mb-10 g-mb-0--md">
                                        <ul className="list-inline text-center text-md-left mb-0">
                                            <li className="list-inline-item">
                                                <a className="g-color-gray-dark-v6 g-color-lightblue-v3--hover"
                                                   href="https://corkdrinks.com/contact" target="_blank">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End Footer Nav */}
                                    {/* Footer Socials */}
                                    <div className="col-md-4 g-mb-10 g-mb-0--md">
                                        <ul className="list-inline g-font-size-16 text-center mb-0">
                                            <li className="list-inline-item g-mx-10">
                                                <a href="https://www.facebook.com/corkdrinks" target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="g-color-facebook g-color-lightblue-v3--hover">
                                                    <i className="fa fa-facebook-square"/>
                                                </a>
                                            </li>
                                            <li className="list-inline-item g-mx-10">
                                                <a href="http://instagram.com/corkdrinks" target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="g-color-black g-color-lightblue-v3--hover">
                                                    <i className="fa fa-instagram"/>
                                                </a>
                                            </li>
                                            <li className="list-inline-item g-mx-10">
                                                <a href="https://twitter.com/CorkDrinks" target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="g-color-twitter g-color-lightblue-v3--hover">
                                                    <i className="fa fa-twitter"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4 text-center text-md-right">
                                        <small className="d-block g-font-size-default">© 2017 Cork & Paw LLC. All Rights
                                            Reserved.
                                        </small>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const LOADING_TYPES = [
    {WIDTH: 55, CLIP: PacmanLoader},
    {WIDTH: 75, CLIP: BounceLoader},
    {WIDTH: 50, CLIP: GridLoader},
    {WIDTH: 100, CLIP: HashLoader},
    {WIDTH: 125, CLIP: RingLoader}
];

class LoadingScreen extends Component {
    render() {
        let Type = LOADING_TYPES[Math.floor(Math.random() * LOADING_TYPES.length)];
        let DataType = Type.CLIP;
        let Width = Type.WIDTH;

        return (
            <div>
                <div style={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    zIndex: 4999
                }}/>

                <div style={{
                    position: "fixed",
                    left: "calc(50% - " + (Width / 2) + "px)",
                    top: "calc(50% - " + (Width / 2) + "px)",
                    zIndex: 5000
                }}>
                    <DataType
                        sizeUnit={"px"}
                        size={Width}
                        color={'#123abc'}
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

let PartnerHandler = withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerComponent));

let store = createStore(combineReducers({
    user: userReducers,
    partner: partnerReducers
}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={LoginScreen}/>
                <Route path="/404" component={FourZeroFour}/>
                <Route exact path="/*" component={PartnerHandler}/>
            </Switch>
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
);