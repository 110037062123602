import React, {Component} from 'react'
import Table from "../../../modules/Table";
import {toDollars} from "../../../util/Util";
let moment = require("moment");

class OrdersTab extends Component {
    render() {
        const {ID} = this.props.match.params;
        let user = {FIRST_NAME: "Unknown", LAST_NAME: "User"};

        let members = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        if (typeof members[ID] !== "undefined") {
            user = members[ID];
        }
        let tips = this.props.partner.tips.reduce((dict, item) => {
            dict[item.ORDER_ID] = item;
            return dict;
        }, {});

        let Drinks = this.props.partner.orders.filter((item) => {
            return item.ACCOUNT_ID === parseInt(ID);
        }).map((item) => {
            let Time = moment(new Date(item.DATE_SENT));
            return {
                ID: item.ID,
                TIME: Time.format('h:mm a'),
                PRICE: toDollars(item.SUBTOTAL),
                TIP: tips[item.ID] ? tips[item.ID].AMOUNT : "None",
                DATE: Time.format('ddd MM/DD/YYYY'),
                LINK_ID: item.ID
            }
        });

        return (
            <Table header={{
                ID: {
                    NAME: "Identifier",
                    WIDTH: 5,
                    LINK: "/order/",
                    LINK_ID: "ID"
                },
                PRICE: {
                    NAME: "Price",
                    WIDTH: 10,
                },
                TIP: {
                    NAME: "Tip",
                    WIDTH: 10,
                },
                TIME: {
                    NAME: "Time",
                    WIDTH: 5
                },
                DATE: {
                    NAME: "Date",
                    WIDTH: 15,
                    SORT: "TIMESTAMP"
                }
            }} sort="ID" data={Drinks} desc={true} title="Orders"/>
        );
    }
}

export default OrdersTab;