import React, {Component} from 'react'
import {request, toDollars} from "../../../util/Util";

class News extends Component {
    state = {
        show: false
    };

    test(e) {
        if (!this.state.show) {
            return;
        }

        if (e.target && e.target.classList && e.target.classList.contains("no-close")) {
            return;
        }

        this.setState({show: false})
    }

    componentWillMount() {
        window.addEventListener("click", this.test.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.test.bind(this));
    }

    deleteNews() {
        let {ID} = this.props.item;
        if (!window.confirm("Are you sure you want to delete this product? There is no going back!")) {
            return;
        }

        request("product/" + ID, "DELETE", {}, (err) => {
            if (err) {
                alert("Internal Error");
                return;
            }

            let products = this.props.partner.products;
            products.some((item, i) => {
                if (item.ID === ID) {
                    products.splice(i, 1);
                    return true;
                }
            });

            this.props.updateProducts(products);
        });
    }

    toggleEnabled() {
        let {ID} = this.props.item;

        request("product/" + ID + "/toggle", "POST", {}, (err) => {
            if (err) {
                alert("Internal Error");
                return;
            }

            let products = this.props.partner.products;
            products.some((item, i) => {
                if (item.ID === ID) {
                    products.splice(i, 1, {...item, ENABLED: !item.ENABLED});
                    return true;
                }
            });

            this.props.updateProducts(products);
        });
    }

    render() {
        let {item} = this.props;

        return (
            <div className="col-md-4 g-mb-30">
                <div className="d-flex flex-wrap h-100 g-brd-around g-brd-gray-light-v7 g-rounded-2 g-pa-25">
                    <header className="w-100 align-self-start text-right justify-content-end g-pos-rel g-mb-10">
                        {/*<span className="u-badge-v2--xs g-absolute-centered--y g-left-0 g-transform-origin--top-left g-bg-darkblue-v2">*/}
                        {/*<span>Live</span>*/}
                        {/*</span>*/}
                        <span className="g-absolute-centered--y g-left-0 g-transform-origin--top-left">
                            <span
                                className={"u-badge-v2--xs g-absolute-centered--y g-left-0 g-transform-origin--top-left " + (item.ENABLED ? "g-bg-darkblue-v2" : "g-bg-darkred")}/>
                            <span style={{marginLeft: 15}}>{item.ENABLED ? "Active" : "Disabled"}</span>
                        </span>
                        <div className="media-body d-flex justify-content-end">
                            <div className="g-pos-rel g-z-index-2">
                                <a
                                    className="u-link-v5 g-line-height-0 g-font-size-24 g-color-gray-light-v6 g-color-secondary--hover show-cursor"
                                    onClick={() => this.setState({show: !this.state.show})}
                                >
                                    <i className="hs-admin-more-alt g-ml-20 no-close"/>
                                </a>

                                <div
                                    className="u-shadow-v31 g-pos-abs g-right-0 g-bg-white u-dropdown--jquery-slide slideInDms"
                                    style={{height: this.state.show ? "auto" : "0", overflow: "hidden"}}>
                                    <ul className="list-unstyled g-nowrap mb-0">
                                        <li className="show-cursor">
                                            <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                               onClick={() => {
                                                   this.props.onClick();
                                                   this.setState({show: false});
                                               }}>
                                                <i className="hs-admin-pencil g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"></i>
                                                Edit
                                            </a>
                                        </li>
                                        <li className="show-cursor">
                                            <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                               onClick={() => {
                                                   this.toggleEnabled();
                                                   this.setState({show: false});
                                               }}>
                                                {item.ENABLED ?
                                                    <i className="fa fa-toggle-off g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md" /> :
                                                    <i className="fa fa-toggle-on g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md" />
                                                }
                                                {item.ENABLED ? "Disable" : "Enable"}
                                            </a>
                                        </li>
                                        <li className="show-cursor">
                                            <a className="d-flex align-items-center u-link-v5 g-bg-gray-light-v8--hover g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-px-25 g-py-14"
                                               onClick={() => {
                                                   this.deleteNews();
                                                   this.setState({show: false});
                                               }}>
                                                <i className="hs-admin-trash g-font-size-18 g-color-gray-light-v6 g-mr-10 g-mr-15--md"></i>
                                                Delete
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className="w-100 align-self-center text-center g-color-darkblue-v2 g-mb-30">
                        <img alt="Logo" src={item.LOGO === null ?
                            "http://localhost:3000/assets/img-temp/130x130/img1.jpg" :
                            "https://s3.amazonaws.com/drip-drinks/" + item.LOGO} style={{width: 100, height: 100}}/>
                    </section>
                    <footer className="w-100 align-self-end text-center">
                        <div className="d-flex align-items-center justify-content-center g-mb-10">
                            <span className="g-line-height-1_2 g-font-size-default g-color-black">{item.NAME}</span>
                        </div>
                        <p className="g-font-weight-300 g-font-size-default g-color-gray-dark-v6 mb-0">${toDollars(item.PRICE)}</p>
                    </footer>
                </div>
            </div>
        );
    }
}

export default News;