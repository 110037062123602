import React, {Component} from 'react'
import CorkButton from "./CorkButton";
import PropTypes from 'prop-types';

class Fancy extends Component {
    state = {
        fancy: false
    };

    show() {
        this.open();
    }

    open() {
        this.setState({fancy: true});
        document.body.style.overflow = "hidden"
    }

    hide() {
        this.close();
    }

    close() {
        document.body.style.overflow = "auto";

        if (this.state.fancy === false) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.setState({fancy: false}, () => {
                resolve();
            });

            if (typeof this.props.onClose !== "undefined") {
                this.props.onClose();
            }
        });
    }

    clickClose(e) {
        if (!this.state.fancy) {
            return;
        }

        if (e.target.className === "fancybox-slide fancybox-slide--inline u-fancybox-slide fancybox-slide--current fancybox-slide--complete") {
            this.close()
        }
    }

    componentWillMount() {
        window.addEventListener("click", this.clickClose.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.clickClose.bind(this));
    }

    render() {
        let {cancelClick} = this.props;
        if (!this.state.fancy) {
            return <div/>
        }

        let Delete = <div/>
        if (typeof this.props.onDelete !== "undefined") {
            Delete = (
                <CorkButton type={CorkButton.TYPES.RED} marginRight={true} onClick={() => this.props.onDelete()}>
                    Delete
                </CorkButton>
            )
        }

        return (
            <div className="fancybox-container u-fancybox-theme fancybox-is-open">
                <div className="fancybox-content">
                    <div className="fancybox-bg"/>

                    <div className="fancybox-slider-wrap">
                        <div className="fancybox-stage">
                            <div
                                className="fancybox-slide fancybox-slide--inline u-fancybox-slide fancybox-slide--current fancybox-slide--complete">
                                <div className="rounded-0 p-0"
                                     style={{display: "inline-block", width: 790, maxWidth: "100%"}}>
                                    <header className="g-bg-gray-light-v8 g-px-15 g-px-30--sm g-py-20">
                                        <h2 className="g-font-weight-300 g-font-size-16 g-color-black mb-0">
                                            {this.props.name}
                                        </h2>

                                        <button data-fancybox-close="" onClick={() => this.close()}
                                                className="btn g-pos-abs g-top-25 g-right-30 g-line-height-1 g-bg-transparent g-font-size-16 g-color-gray-light-v6 g-brd-none p-0"
                                                title=""><i className="hs-admin-close"></i></button>
                                    </header>

                                    <div className="g-pa-15 g-pa-30--sm">
                                        {this.props.children}

                                        <div className="d-flex">
                                            <CorkButton onClick={() => {
                                                if (cancelClick) {
                                                    return cancelClick(this.close.bind(this));
                                                }

                                                this.close();
                                            }} type={CorkButton.TYPES.DISABLED}>
                                                {this.props.cancelText ? this.props.cancelText : "Cancel"}
                                            </CorkButton>
                                            <div style={{flex: 1}}/>
                                            {this.props.buttons}
                                            {Delete}
                                            <CorkButton onClick={() => {
                                                if (typeof this.props.onClick !== "undefined") {
                                                    this.props.onClick();
                                                }
                                            }}>
                                                {this.props.buttonText ? this.props.buttonText : "Create"}
                                            </CorkButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Fancy.propTypes = {
    buttonText: PropTypes.string,
    buttons: PropTypes.array,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.array.isRequired,
    cancelText: PropTypes.string,
    cancelClick: PropTypes.func
};

export default Fancy;