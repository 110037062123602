import React, {Component} from 'react'

class Noty extends Component {
    close() {
        let {item} = this.props;
        let announcements = this.props.partner.announcements;
        let index = announcements.findIndex((announcement) => {
            return item.ID === announcement.ID;
        });

        if (index === -1) {
            return;
        }

        announcements.splice(index, 1);
        this.props.updateAnnouncements(announcements);

        let found = localStorage.getItem("announcements");
        if (found === null) {
            found = [];
        } else {
            found = JSON.parse(found);
        }

        found.push(item.ID);
        localStorage.setItem("announcements", JSON.stringify(found));
    }

    render() {
        let {item} = this.props;

        let typeClass = "noty_type__success";
        let typeIcon = "hs-admin-check";

        switch (item.TYPE) {
            case 1:
                typeClass = "noty_type__info";
                typeIcon = "hs-admin-info";
                break;
            case 2:
                typeClass = "noty_type__warning";
                typeIcon = "hs-admin-bolt";
                break;
            case 3:
                typeClass = "noty_type__error";
                typeIcon = "hs-admin-alert";
                break;
        }

        return (
            <div onClick={this.close.bind(this)}
                className={"noty_bar noty_theme__unify--v1 noty_close_with_click noty_has_progressbar noty_close_with_button " + typeClass}>
                <div className="noty_body">
                    <div className="g-mr-20">
                        <div className="noty_body__icon"><i className={typeIcon}/></div>
                    </div>
                    <div>
                        <div style={{fontWeight: "bold"}}>{item.TITLE}</div>
                        <div>{item.MESSAGE}</div>
                    </div>
                </div>
                <div className="noty_progressbar"/>
                <div className="noty_close_button">×</div>
            </div>
        );
    }
}

export default Noty;