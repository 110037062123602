import React, {Component} from 'react'
import {CorkTable} from "corky";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment'

class TicketsScreen extends Component {
    render() {
        let data = this.props.partner.demos.map((item) => {
            return {
                ...item,
                DATE: moment(item.DATE_SENT).format("hh:ss a MM/DD/YY")
            }
        });

        return (
            <div className="g-pa-20">
                <div className="row">
                    <CorkTable header={{
                        ID: {
                            NAME: "",
                            WIDTH: 10
                        },
                        NAME: {
                            NAME: "Name",
                            WIDTH: 15
                        },
                        EMAIL: {
                            NAME: "Email",
                            WIDTH: 20
                        },
                        PHONE: {
                            NAME: "Phone",
                            WIDTH: 15
                        },
                        LOCATION: {
                            NAME: "Location",
                            WIDTH: 15
                        },
                        POSITION: {
                            NAME: "Position",
                            WIDTH: 10
                        },
                        DATE: {
                            NAME: "Date Sent",
                            WIDTH: 15
                        }
                    }} sort="ID" data={data} desc={true} title="Demo Requests"/>
                </div>
            </div>
        )
    }
}

export default TicketsScreen;
