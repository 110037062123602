import React, {Component} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import {Chart} from "react-charts";
import {COLORS, request} from "../../../util/Util";
import Select from "react-select";
import {BounceLoader, GridLoader, HashLoader, PacmanLoader, RingLoader} from "react-spinners";

const DAY = 1000 * 60 * 60 * 24;
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const HOURS_OF_DAY = ["12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "12pm", "1pm",
    "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm"];

const LOADING_TYPES = [
    {WIDTH: 55, CLIP: PacmanLoader},
    {WIDTH: 75, CLIP: BounceLoader},
    {WIDTH: 50, CLIP: GridLoader},
    {WIDTH: 100, CLIP: HashLoader},
    {WIDTH: 125, CLIP: RingLoader}
];

const DAY_TYPE = [
    {label: "All Day", value: 0},
    {label: "Custom", value: 1},
];

const HOUR = 1000 * 60 * 60;
let HOURS = [
    {label: "12am", value: 0},
    {label: "1am", value: HOUR},
    {label: "2am", value: HOUR * 2},
    {label: "3am", value: HOUR * 3},
    {label: "4am", value: HOUR * 4},
    {label: "5am", value: HOUR * 5},
    {label: "6am", value: HOUR * 6},
    {label: "7am", value: HOUR * 7},
    {label: "8am", value: HOUR * 8},
    {label: "9am", value: HOUR * 9},
    {label: "10am", value: HOUR * 10},
    {label: "11am", value: HOUR * 11},
    {label: "12pm", value: HOUR * 12},
    {label: "1pm", value: HOUR * 13},
    {label: "2pm", value: HOUR * 14},
    {label: "3pm", value: HOUR * 15},
    {label: "4pm", value: HOUR * 16},
    {label: "5pm", value: HOUR * 17},
    {label: "6pm", value: HOUR * 18},
    {label: "7pm", value: HOUR * 19},
    {label: "8pm", value: HOUR * 20},
    {label: "9pm", value: HOUR * 21},
    {label: "10pm", value: HOUR * 22},
    {label: "11pm", value: HOUR * 23},
    {label: "12am", value: HOUR * 24},
]

class Report extends Component {
    state = {data: [], backup: [], type: null, frame: 1000 * 60 * 60 * 24, lockMultiselect: false, loading: false};

    constructor(props) {
        super(props);

        this.perCalc = () => {}
        this.cards = [];
    }


    saveData = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            var json = data,
                blob = new Blob([json], {type: "octet/stream"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());

    async updateData(start, end, locations, frame) {
        let {reporting, orders} = this.props.partner;
        let {day, last, frame: lastFrame} = reporting;

        let str = JSON.stringify({start, end, locations});
        if (last === str) {
            console.log("YO");
            return Promise.resolve();
        }

        if (!frame) {
            frame = lastFrame;
        }

        // let {orders, products, categories, drawers} = await request(`dashboard/reports/${searchStart}/${searchEnd}?locations=${encodeURIComponent(locations.map(({value}) => value))}`, "GET", null);
        // let data = {orders, products, categories, drawers, tips: []};

        let data = {orders, products: [], categories: [], drawers: [], tips: []};

        if (day !== null) {
            data = this.hourSort(day, false, data);
        }

        console.log(data);
        this.props.updateReporting({...reporting, start, end, frame, data, backup: data, locations, last: str})

        return Promise.resolve();
    }

    hourSort(day, update = true, override) {
        let {reporting} = this.props.partner;
        let {backup} = reporting;

        if (override) {
            backup = override;
        }

        let copy = JSON.parse(JSON.stringify(backup));
        let {orders, products, categories, drawers} = copy;

        if (day === null) {
            if (update) {
                this.props.updateReporting({...reporting, data: backup, day});
            }

            return backup;
        }

        console.log(orders.length);
        orders = orders.filter((item) => {
            let offset = item.DATE_SENT - moment(item.DATE_SENT).startOf("day").valueOf();

            return day.start < offset && day.end > offset;
        });

        console.log(orders.length);
        products = products.filter((item) => {
            let offset = item.DATE_SENT - moment(item.DATE_SENT).startOf("day").valueOf();

            return day.start < offset && day.end > offset;
        });

        categories = categories.filter((item) => {
            let offset = item.DATE_SENT - moment(item.DATE_SENT).startOf("day").valueOf();

            return day.start < offset && day.end > offset;
        });

        let data = {orders, products, categories, drawers};
        if (update) {
            this.props.updateReporting({...reporting, data, day});
        }

        return data;
    }

    async lockMultiselect() {
        let {lockMultiselect} = this.state;

        if (lockMultiselect) {
            return;
        }

        this.setState({lockMultiselect: true});

        let {location, reporting} = this.props.partner;

        if (reporting.locations.length !== 1) {
            this.props.updateReporting({...reporting, locations: [{label: location.NAME, value: location.ID}]});

            await this.updateData();
        }
    }

    unlockMultiselect() {
        this.setState({lockMultiselect: false});
    }

    getData() {
        let {locations, data} = this.props.partner.reporting;

        let toReturn = {orders: [], tips: []};
        // for (let key of Object.keys(data)) {
        //     if (locations.findIndex((item) => {
        //         return item.value === parseInt(key)
        //     }) === -1) {
        //         continue;
        //     }
        //
        //     let value = data[key];
        //     toReturn = {
        //         orders: [...toReturn.orders, ...value.orders],
        //         tips: [...toReturn.tips, ...value.tips]
        //     }
        // }

        return toReturn;
    }

    download() {
        let {start, end} = this.props.partner.reporting;

        request(this.downloadUrl, "POST", {START_DATE: start, END_DATE: end}).then((payload) => {
            this.saveData(payload, this.downloadName);
        });
    }

    async quick(i) {
        let {reporting} = this.props.partner;
        let {locations} = reporting;
        this.setState({type: i});

        switch (i) {
            case 0:
                return this.updateData(moment().startOf("day").valueOf(), moment().endOf("day").valueOf(), locations, DAY);
            case 1:
                return this.updateData(moment().startOf("isoWeek").valueOf(), moment().endOf("isoWeek").valueOf(), locations, DAY * 7);
            case 2:
                return this.updateData(moment().startOf("month").valueOf(), moment().endOf("month").valueOf(), locations, DAY * 31);
            case 3:
                return this.updateData(moment().startOf("month").valueOf(), moment().endOf("month").valueOf(), locations, DAY * 31);
        }
    }

    getFilterData() {
        let {start, end, data} = this.props.partner.reporting;

        return [];
    }

    getFilterFunction() {
        let {start, end} = this.props.partner.reporting;

        return (item) => start < item[this.sort] && item[this.sort] < end;
    }

    renderWeekDay() {
        if (this.noDayWeek) {
            return <div/>
        }

        let {start, end, data: rawData} = this.props.partner.reporting;
        let {orders: data} = rawData;

        let filteredData = data.filter((item) => start < item[this.sort] && item[this.sort] < end);

        let perDay = [0, 0, 0, 0, 0, 0, 0];
        let perHour = HOURS_OF_DAY.map(() => 0);
        for (let dat of filteredData) {
            let order = new Date(dat[this.sort]);
            perDay[order.getDay()] += this.valCal(dat);
            perHour[order.getHours()] += this.valCal(dat);
        }

        return (
            <div className="row">
                <div className="col-xl-4">
                    <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                        <header className="media g-mb-30">
                            <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                {this.name} Per Day of Week
                            </h3>
                        </header>

                        <Chart
                            series={{type: 'bar'}}
                            style={{
                                width: "400px",
                                height: "200px",
                            }}
                            data={[{
                                label: this.name,
                                data: perDay.map((item, i) => [DAYS_OF_WEEK[i], item])
                            }]}
                            axes={[
                                {primary: true, type: "ordinal", position: "bottom"},
                                {type: "linear", position: "left"}
                            ]}
                            primaryCursor
                            tooltip
                        />
                    </div>
                </div>

                <div className="col-xl-8">
                    <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                        <header className="media g-mb-30">
                            <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                {this.name} Per Hour
                            </h3>
                        </header>

                        <Chart
                            series={{type: 'bar'}}
                            style={{
                                width: "400px",
                                height: "200px",
                            }}
                            data={[{
                                label: this.name,
                                data: perHour.map((item, i) => [HOURS_OF_DAY[i], item])
                            }]}
                            axes={[
                                {primary: true, type: "ordinal", position: "bottom"},
                                {type: "linear", position: "left"}
                            ]}
                            primaryCursor
                            tooltip
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let {type, loading} = this.state;
        let {reporting, overseer} = this.props.partner;
        let {data: rawData, start, end, frame, locations, day} = reporting;
        let {orders: data} = rawData;

        if (loading) {
            let Type = LOADING_TYPES[Math.floor(Math.random() * LOADING_TYPES.length)];
            let DataType = Type.CLIP;
            let Width = Type.WIDTH;

            return (
                <div>
                    <div style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        zIndex: 4999
                    }}/>

                    <div style={{
                        position: "fixed",
                        left: "calc(50% - " + (Width / 2) + "px)",
                        top: "calc(50% - " + (Width / 2) + "px)",
                        zIndex: 5000
                    }}>
                        <DataType
                            sizeUnit={"px"}
                            size={Width}
                            color={'#123abc'}
                            loading={true}
                        />
                    </div>
                </div>
            )
        }

        let filteredData = data.filter((item) => start < item[this.sort] && item[this.sort] < end);
        let doneData = [], current = start;
        while (current < end) {
            let leMount = this.perCalc(filteredData.filter((item) => item[this.sort] > current && item[this.sort] < current + (1000 * 60 * 60 * 24)));

            doneData.push([
                moment(new Date(current)).format("MM/DD"),
                leMount
            ]);

            current += (1000 * 60 * 60 * 24);
        }

        let DaysGraph = <div/>;
        if (frame > DAY && this.customDayReport) {
            DaysGraph = this.customDayReport();
        } else if (frame > DAY && !this.noShowDays) {
            DaysGraph = (
                <div className="col-xl-12">
                    {/* Statistic Card */}
                    <div className="card g-brd-gray-light-v7 g-pa-15 g-pa-25-30--md g-mb-30">
                        <header className="media g-mb-30">
                            <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                {this.name} Per Day
                            </h3>
                        </header>

                        <section>
                            <Chart
                                style={{
                                    width: "400px",
                                    height: "300px",
                                }}
                                data={[{
                                    label: this.name,
                                    data: doneData
                                }]}
                                axes={[
                                    {primary: true, type: "ordinal", position: "bottom"},
                                    {
                                        type: "linear",
                                        hardMin: 0,
                                        position: "left",
                                        color: "purple",
                                        format: this.axeTick ? e => this.axeTick(e) : undefined
                                    }
                                ]}
                                primaryCursor
                                tooltip
                            />
                        </section>
                    </div>
                    {/* End Statistic Card */}
                </div>
            );
        }

        let Cards = this.cards.map((item) => {
            return (
                <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30">
                    <div className="card h-80 g-brd-gray-light-v7 rounded">
                        <div className="card-block g-pa-20">
                            <h4 className="media g-font-weight-300 g-font-size-16 g-mb-10 g-mb-20--md">
                                    <span className="d-flex align-self-center g-color-gray-dark-v6 g-font-weight-400">
                                        {item.LABEL}
                                    </span>
                                <span
                                    className="media-body align-self-center text-right g-font-size-18--md g-color-black">
                                        {item.CALC(filteredData)}
                                    </span>
                            </h4>
                            <p className="g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                {item.DESCRIPTION}
                            </p>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="g-pa-20">
                <div className="row" style={{margin: "0 20px", marginBottom: 10, justifyContent: "space-between"}}>
                    <div className="row">
                        <div style={{width: 265}}>
                            <div className="form-control" style={{
                                borderColor: "#e1eaea", display: "flex", alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <div className="select-color-report"
                                   style={{padding: "2px 15px 2px 5px", borderRight: "#cccccc 1px solid"}}
                                   onClick={async () => {
                                       await this.updateData(new Date(start - frame).getTime(), new Date(end - frame).getTime(), locations);
                                   }}>
                                    <i className="fal fa-chevron-left" />
                                </div>

                                <DateRangePicker startDate={moment(start)} endDate={moment(end)} showDropdowns ranges={{
                                    'Today': [moment().startOf("day"), moment().endOf("day")],
                                    'Yesterday': [moment().startOf("day").subtract(1, 'days'), moment().endOf("day").subtract(1, 'days')],
                                    'Last 7 Days': [moment().startOf("day").subtract(6, 'days'), moment().endOf("day")],
                                    'Last 30 Days': [moment().startOf("day").subtract(29, 'days'), moment().endOf("day")],
                                    'This Month': [moment().startOf('month'), moment().endOf("day").endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                }} onApply={async (_, e) => {
                                    await this.updateData(e.startDate.valueOf(), e.endDate.valueOf(), locations, e.endDate.valueOf() - e.startDate.valueOf());
                                }} maxDate={moment(new Date())}>
                                    <div style={{flex: 1, marginLeft: 10}} className="align-items-center g-pr-10 select-report-day">
                                        <input style={{marginRight: 5, padding: "7px 0", border: "0px"}}
                                               className="g-font-size-12 g-font-size-default--md oneThreeFive"
                                               type="text"
                                               readOnly="readonly"
                                               value={moment(start).format("MM/DD/YY") + " - " + moment(end).format("MM/DD/YY")}/>
                                        <i className="hs-admin-angle-down"
                                           style={{marginTop: 12}}/>
                                    </div>
                                </DateRangePicker>

                                <div className="select-color-report"
                                   style={{padding: "2px 5px 2px 15px", borderLeft: "#e1eaea 1px solid"}}
                                   onClick={async () => {
                                       await this.updateData(new Date(start + frame).getTime(), new Date(end + frame).getTime(), locations);
                                   }}>
                                    <i className="fal fa-chevron-right" />
                                </div>
                            </div>
                        </div>

                        <div style={{width: 150, marginLeft: 12}}>
                            <Select menuPortalTarget={document.body} placeholder="Quick Select" styles={{
                                menuPortal: base => ({...base, zIndex: 99999}),
                                menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                container: (base, {isFocused}) => ({
                                    ...base, paddingTop: 3, paddingBottom: 4,
                                    borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                                }),
                                control: (base) => ({...base, border: 0, boxShadow: 0, '&:hover': {border: 0}})
                            }} options={DAY_TYPE} className="form-control"
                                    value={day === null ? DAY_TYPE[0] : DAY_TYPE[1]}
                                    onChange={({value}) => value === 0 ? this.hourSort(null) : this.hourSort({
                                        start: HOURS[0].value, end: HOURS[24].value
                                    })}/>
                        </div>

                        {day === null ? <div/> : (
                            <div style={{flexDirection: "row", display: "flex"}}>
                                <div style={{width: 125, marginLeft: 12}}>
                                    <Select menuPortalTarget={document.body} placeholder="Quick Select" styles={{
                                        menuPortal: base => ({...base, zIndex: 99999}),
                                        menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                        container: (base, {isFocused}) => ({
                                            ...base, paddingTop: 3, paddingBottom: 4,
                                            borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                                        }),
                                        control: (base) => ({...base, border: 0, boxShadow: 0, '&:hover': {border: 0}})
                                    }} options={HOURS} className="form-control"
                                            value={HOURS.find(({value}) => value === day.start)}
                                            onChange={({value}) => this.hourSort({...day, start: value})}/>
                                </div>

                                <div style={{width: 125, marginLeft: 12}}>
                                    <Select menuPortalTarget={document.body} placeholder="Quick Select" styles={{
                                        menuPortal: base => ({...base, zIndex: 99999}),
                                        menuList: () => ({maxHeight: 150, overflowY: "scroll"}),
                                        container: (base, {isFocused}) => ({
                                            ...base, paddingTop: 3, paddingBottom: 4,
                                            borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                                        }),
                                        control: (base) => ({...base, border: 0, boxShadow: 0, '&:hover': {border: 0}})
                                    }} options={HOURS} className="form-control"
                                            value={HOURS.find(({value}) => value === day.end)}
                                            onChange={({value}) => this.hourSort({...day, end: value})}/>
                                </div>
                            </div>
                        )}
                    </div>

                    {/*<div className="d-flex" style={{border: "1px solid gray", borderRadius: 5, float: "right"}}>*/}
                    {/*    <CorkDrop items={[*/}
                    {/*        {NAME: "As CSV", VALUE: 0}*/}
                    {/*    ]} label="Export" value={type} onChange={(type) => {*/}
                    {/*        this.download(type)*/}
                    {/*    }}/>*/}
                    {/*</div>*/}
                </div>

                {/*<div className="row">*/}
                {/*    {Cards}*/}
                {/*</div>*/}

                {/*{DaysGraph}*/}

                {/*{this.renderWeekDay()}*/}
            </div>
        )
    }
}

export default Report;