import React, {Component} from 'react'
import Double from "../../../modules/Double";
import {request} from "../../../util/Util";

let moment = require("moment");
let chrono = require('chrono-node');

class HoursTab extends Component {
    state = {
        hours: {
            SUNDAY_OPEN: "",
            SUNDAY_CLOSE: "",
            MONDAY_OPEN: "",
            MONDAY_CLOSE: "",
            TUESDAY_OPEN: "",
            TUESDAY_CLOSE: "",
            WEDNESDAY_OPEN: "",
            WEDNESDAY_CLOSE: "",
            THURSDAY_OPEN: "",
            THURSDAY_CLOSE: "",
            FRIDAY_OPEN: "",
            FRIDAY_CLOSE: "",
            SATURDAY_OPEN: "",
            SATURDAY_CLOSE: ""
        }
    };

    componentDidMount() {
        if (this.props.partner.hours === null) {
            return;
        }

        let clone = JSON.parse(JSON.stringify(this.props.partner.hours));
        for (let key of Object.keys(clone)) {
            if (key === "ID" || key === "LOCATION_ID") {
                continue;
            }

            let option = clone[key];
            if (option === null) {
                clone[key] = "CLOSED";
                continue;
            }

            clone[key] = moment(new Date(option)).format('h:mm a');
        }

        this.setState({hours: clone});
    }

    save() {
        let {hours} = this.state;

        let clone = JSON.parse(JSON.stringify(hours));
        for (let key of Object.keys(clone)) {
            if (key === "ID" || key === "LOCATION_ID") {
                continue;
            }

            let option = clone[key];
            if (option.trim() === "") {
                alert("Please fill out every input with either a time or CLOSED");
                return;
            }

            if (option.toLowerCase() === "closed") {
                clone[key] = null;
            } else {
                try {
                    clone[key] = chrono.parseDate(option).getTime();
                } catch(e) {
                    alert("We are having trouble parsing one of your times. Please make sure it's a time using the " +
                        "format 12:00 PM or if the shop is closed have the time be CLOSED for both open and closed");

                    return;
                }
            }
        }

        request("hours", "POST", clone).then(() => {
            this.props.updateHours(clone);
            alert("Hours has been updated");
        }).catch(() => {
            alert("There was a problem saving your changes");
        })
    }

    render() {
        let {hours} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Hours
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <Double label="Sunday" start={hours.SUNDAY_OPEN} end={hours.SUNDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, SUNDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, SUNDAY_CLOSE: txt}})}
                    />

                    <Double label="Monday" start={hours.MONDAY_OPEN} end={hours.MONDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, MONDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, MONDAY_CLOSE: txt}})}
                    />
                    <Double label="Thursday" start={hours.TUESDAY_OPEN} end={hours.TUESDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, TUESDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, TUESDAY_CLOSE: txt}})}
                    />
                    <Double label="Wednesday" start={hours.WEDNESDAY_OPEN} end={hours.WEDNESDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, WEDNESDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, WEDNESDAY_CLOSE: txt}})}
                    />
                    <Double label="Thursday" start={hours.THURSDAY_OPEN} end={hours.THURSDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, THURSDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, THURSDAY_CLOSE: txt}})}
                    />
                    <Double label="Friday" start={hours.FRIDAY_OPEN} end={hours.FRIDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, FRIDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, FRIDAY_CLOSE: txt}})}
                    />
                    <Double label="Saturday" start={hours.SATURDAY_OPEN} end={hours.SATURDAY_CLOSE}
                            startPlaceholder="Ex. 8:00AM or CLOSED" endPlaceholder="Ex. 8:00PM or CLOSED"
                            onStartChange={(txt) => this.setState({hours: {...hours, SATURDAY_OPEN: txt}})}
                            onEndChange={(txt) => this.setState({hours: {...hours, SATURDAY_CLOSE: txt}})}
                    />

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <div>
                        <button style={{backgroundColor: "#326489"}}
                                onClick={() => this.save()}
                                className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10"
                                type="submit">Save Changes
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default HoursTab;