import React, {Component} from 'react'
import {request} from '../../util/Util'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LoginScreen extends Component {
    state = {loginStatus: null, token: null};

    componentDidMount() {
        document.title = "Drip Admin";
    }

    componentWillMount() {
        request('admin/valid', 'GET', null, (err, payload) => {
            if (err) {
                return;
            }

            window.location.href = '/';
        });
    }

    login() {
        let element = document.getElementById("token");
        let _token = element.value.trim();
        let {token} = this.state;

        if (token === null) {
            if (_token[0] !== "1") {
                _token = "1" + _token;
            }

            request('login/initiate', 'POST', {PHONE: _token}, (err, payload) => {
                if (err) {
                    alert("ERROR LOGGING IN");
                    return;
                }

                element.value = "";
                this.setState({token: payload});
            });

            return;
        }

        request('login/complete', 'POST', {UNIQUE: token, TOKEN: _token, CLIENT: {
                NAME: "Admin",
                INFO: "Website",
                TYPE: 2
            }}, (err, payload) => {
            if (err) {
                alert("ERROR LOGGING IN");
                return;
            }

            window.localStorage.setItem("TOKEN", payload);
            window.location.href = '/';
        });
    }

    render() {
        let {token} = this.state;
        let loginStatus = this.state.loginStatus === null ? <span/> :
            <span style={{color: "darkred"}}>{this.state.loginStatus}</span>;

        return (
            <div style={{marginTop: "12%"}}>
                <div className="container g-pb-30">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-4 text-center g-mb-70" style={{marginTop: 45}}>
                            <h2 className="h5 mb-4">Sign into your partner dashboard</h2>
                            {/* Login Form */}
                            {loginStatus}
                            <div className="g-mb-30">
                                <div className="g-mb-20">
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded-0 g-py-13 g-px-15"
                                        type="text" placeholder={token === null ? "Input Phone Number" : "Input Token from Phone"} id="token"/>
                                </div>
                                <button
                                    className="btn btn-block u-btn-primary g-color-white g-bg-primary-dark-v1--hover g-font-weight-600 g-font-size-12 text-uppercase rounded-0 g-px-25 g-py-15"
                                    onClick={(e) =>
                                        this.login()
                                    }>Sign In
                                </button>
                            </div>
                            {/* End Login Form */}
                        </div>
                        <div className="col-md-6 col-lg-5 ml-lg-5 g-mb-70">
                            {/* Icon Block */}
                            <div className="media g-mb-30">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                        <i className="icon-medical-022 u-line-icon-pro"/>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">Don’t miss a thing</h3>
                                    <p>See all the corkers interacting with your bar.</p>
                                </div>
                            </div>
                            {/* End Icon Block */}
                            {/* Icon Block */}
                            <div className="media g-mb-30">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                        <i className="icon-real-estate-083 u-line-icon-pro"/>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">Get metrics</h3>
                                    <p>View the top corkers in your bar, and top hours.</p>
                                </div>
                            </div>
                            {/* End Icon Block */}
                            {/* Icon Block */}
                            <div className="media">
                                <div className="d-flex mr-3">
                                    <span className="u-icon-v1 u-icon-size--lg g-color-text">
                                        <i className="icon-real-estate-014 u-line-icon-pro"/>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h3 className="h5">View your bars info</h3>
                                    <p>View your bars info and request edits if needed.</p>
                                </div>
                            </div>
                            {/* End Icon Block */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginScreen));