import React, {Component} from "react"
import PropTypes from 'prop-types';

class CorkButton extends Component {
    static TYPES = {
        GENERAL: {
            backgroundColor: "#4ACE9C",
            color: "white"
        },
        RED: {
            backgroundColor: "#D43A57",
            color: "white"
        },
        DISABLED: {
            backgroundColor: "#C6C6C6",
            color: "white"
        }
    };

    getType() {
        let {type} = this.props;

        if (type) {
            if (typeof type === "string") {
                return CorkButton.TYPES[this.props.type];
            } else {
                return type;
            }
        }

        return CorkButton.TYPES.GENERAL
    }

    extraStyle() {
        let {marginLeft, marginRight} = this.props;

        if (marginLeft) {
            return {marginLeft: 5}
        }

        if (marginRight) {
            return {marginRight: 5}
        }

        return {}
    }

    render() {
        let {onClick} = this.props;

        return (
            <button style={{...this.getType(), ...this.props.style, ...this.extraStyle(), minWidth: 160}} onClick={onClick}
                    className="btn btn-md btn-xl--md u-btn-secondary g-font-size-12 g-font-size-default--md cork-button"
                    type="submit">
                {this.props.children}
            </button>
        )
    }
}

CorkButton.propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,

    marginLeft: PropTypes.bool,
    marginRight: PropTypes.bool
};

export default CorkButton;
