import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Navbar} from 'corky'


const Elements = [
    {
        name: "Dashboard",
        icon: "hs-admin-layout-media-center-alt",
        to: "/"
    },
    {
        name: "Locations",
        icon: "fa fa-location-arrow",
        to: "/locations"
    },
    {
        name: "Orders",
        icon: "fa fa-shopping-cart",
        to: "/orders"
    },
    {
        name: "Patrons",
        icon: "fa fa-users",
        to: "/patrons"
    },
    {
        id: "reporting_menu",
        name: "Reporting",
        icon: "fa fa-chart-bar",
        kids: [
            {
                name: "Order Summary",
                icon: "fa fa-list",
                to: "/report/summary/orders"
            },
            {
                name: "Sales Summary",
                icon: "fa fa-dollar-sign",
                to: "/report/summary/sales"
            },
            {
                name: "Product Summary",
                icon: "fa fa-user-tag",
                to: "/report/summary/products"
            }
        ]
    },
    {
        id: "tracking_menu",
        name: "Tracking",
        icon: "fa fa-chart-bar",
        kids: [
            {
                name: "Referrals",
                icon: "fa fa-list",
                to: "/referrals"
            },
            {
                name: "Deposits",
                icon: "fa fa-user-tag",
                to: "/deposits"
            },
            {
                name: "Marketing",
                icon: "fa fa-user-tag",
                to: "/marketing"
            }
        ]
    },
    {
        id: "rewards_menu",
        name: "Rewards",
        icon: "fa fa-trophy",
        kids: [
            {
                name: "Redeems",
                icon: "fa fa-star",
                to: "/redeems"
            }
        ]
    },
    {
        id: "support_menu",
        name: "Support ",
        icon: "fa fa-question-circle",
        kids: [
            {
                name: "Tickets",
                icon: "fa fa-ticket-alt",
                to: "/tickets"
            },
            {
                name: "Demo Requests",
                icon: "fa fa-desktop",
                to: "/demos"
            },
            {
                name: "Bugs",
                icon: "fa fa-bug",
                to: "/bugs"
            }
        ]
    },
    {
        name: "News",
        icon: "fa fa-newspaper",
        to: "/news"
    },
    {
        name: "Push Notifications",
        icon: "fa fa-bell",
        to: "/push"
    }
];

class _Navbar extends Component {
    componentDidMount() {
        eval(`$(document).on('mouseover', '.u-pagination-v1__item', function (ev, el) {
                ev.currentTarget.removeAttribute("href");
            });`)
    }

    render() {
        return (
            <Navbar elements={Elements} {...this.props} linkType={Link} />
        );
    }
}

export default _Navbar;
