import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";

let moment = require("moment");
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class GeneralTab extends Component {
    render() {
        const {ID} = this.props.match.params;
        let user = {FIRST_NAME: "Unknown", LAST_NAME: "User"};

        let members = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let member = this.props.partner.members.find((item) => {
            return item.ID === parseInt(ID);
        });

        let drinks = this.props.partner.orders.filter((item) => {
            return item.ACCOUNT_ID === parseInt(ID)
        });

        drinks.sort((a, b) => {
            return a.DATE_REDEEMED - b.DATE_REDEEMED
        });

        if (typeof members[ID] !== "undefined") {
            user = members[ID];
        }

        let drinkDates = [0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < drinks.length; i++) {
            drinkDates[new Date(drinks[i]['DATE_REDEEMED']).getDay()] += 1;
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate">
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem disabled={true} name="Name" value={user.FULL_NAME}/>
                            <FormItem disabled={true} name="Email" value={user.EMAIL}/>
                            <FormItem disabled={true} name="Balance" value={user.BALANCE}/>
                            <FormItem disabled={true} name="Phone" value={phoneUtil.formatInOriginalFormat(phoneUtil.parseAndKeepRawInput(user.PHONE, 'US'), 'US')}/>

                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0"
                                style={{marginTop: 40}}>
                                Time
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                            <FormItem disabled={true} name="Date Created"
                                      value={moment(new Date(member.DATE_CREATED)).format('h:mm A MM/DD/YYYY')}/>

                            <FormItem disabled={true} name="Last Order"
                                      value={drinks.length === 0 ? "No Orders" : moment(new Date(drinks[drinks.length - 1].DATE_SENT)).format('h:mm A MM/DD/YYYY')}/>

                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0"
                                style={{marginTop: 40}}>
                                General Stats
                            </h2>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                            <FormItem disabled={true} name="Favorite Day" value={GeneralTab.getHotDay(drinkDates)}/>
                            <FormItem disabled={true} name="Favorite Time" value="Coming Soon"/>
                            <FormItem disabled={true} name="Favorite Drink" value="Coming Soon"/>

                        </header>
                    </form>
                </div>
            </div>
        )
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}


export default GeneralTab;