import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {COLORS, request} from "../../../util/Util";
import Fancy from "../../../modules/Fancy";
import CorkDrop from "../../../modules/CorkDrop";
import Customization from "../../products/modules/Customization";

class PresetsTab extends Component {
    state = {
        name: "",
        type: 0,
        required: false
    };

    remove(id) {
        const {ID} = this.props.match.params;
        if (!window.confirm("Are you sure you want to remove this customization?")) {
            return;
        }

        request("customization/" + id, "DELETE", {}, (err) => {
            if (err) {
                alert("ERROR SAVING ON SERVER");
                return;
            }

            let presets = this.props.partner.presets;
            let index = presets.findIndex((item) => item.ID === id);

            presets.splice(index, 1);
            this.props.updatePresets(presets);
        });
    }


    add() {
        const {ID} = this.props.match.params;
        let {name, type, required} = this.state;

        request("preset", "POST", {NAME: name, TYPE: type, REQUIRED: required}, (err, id) => {
            if (err) {
                alert("ERRPR");
                return;
            }

            this.fancy.close().then(() => {
                this.props.updatePresets([
                    ...this.props.partner.presets,
                    {ID: id, NAME: name, REQUIRED: required, TYPE: type, OPTIONS: []}
                ]);

                this.setState({name: "", type: 0, required: false});
            });
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let {name, type, required} = this.state;

        let Customizations = this.props.partner.presets.map((item) => {
            return <Customization item={item} id={ID} {...this.props} remove={() => this.remove(item.ID)}/>
        });

        return (
            <div className="col-md-9">
                <Fancy name="Create Preset" ref={(e) => this.fancy = e}
                       onClick={this.add.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={name} onChange={(e) => {
                            this.setState({name: e.target.value})
                        }}
                               aria-invalid="false"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Type</label>

                        <CorkDrop items={[
                            {NAME: "Default", VALUE: 0},
                            {NAME: "Quantity", VALUE: 1},
                            {NAME: "Multi-Select", VALUE: 2},
                            {NAME: "Multi-Select w/ Quality", VALUE: 3}
                        ]} value={type} onChange={(type) => this.setState({type})}/>
                    </div>

                    <div className="form-group d-flex align-items-center justify-content-between g-mb-20">
                        <span>Required</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox" onClick={(e) => this.setState({required: !required})}
                                   checked={required}
                            />

                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>
                </Fancy>

                <div style={{float: "right", margin: 15}}>
                    <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold"}} onClick={() => {
                        this.fancy.open();
                    }}>
                        + Create Preset
                    </a>
                </div>

                <div style={{marginTop: 50}}>
                    {Customizations}
                </div>
            </div>
        )
    }
}

export default PresetsTab;