import React, {Component} from 'react'
import {request, toDollars} from "../../../util/Util";
import {FancyText, FancySelect} from "corky"
import CorkButton from "../../../modules/CorkButton";

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class GeneralTab extends Component {
    save() {
        let {locations} = this.props.partner;
        const {ID} = this.props.match.params;
        let valueDict = this.company.state.value;

        if (valueDict === null) {
            return;
        }

        let company = valueDict.value;
        request("admin/location/" + ID, "PATCH", {COMPANY_ID: company}).then(() => {
            let index = locations.findIndex((item) => item.ID === parseInt(ID));
            locations.splice(index, 1, {...locations[index], COMPANY_ID: company});
            this.props.updateLocations(locations);

            alert("done");
        }).catch(() => {
            alert("ERROR NO!");
        })
    }

    renderOptions() {
        let {companies} = this.props.partner;

        return companies.map((item) => ({
            label: item.NAME,
            value: item.ID
        }));
    }

    render() {
        const {ID} = this.props.match.params;

        let location = this.props.partner.locations.find((item) => item.ID === parseInt(ID));
        if (typeof location === "undefined") {
            return (
                <div>Location not found</div>
            );
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FancyText disabled={true} label="Name" value={location.NAME}/>
                    <FancySelect label="Company" options={this.renderOptions()} ref={(e) => this.company = e}
                                 value={this.renderOptions().find((item) => item.value === location.COMPANY_ID)} />

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <div className="row">
                        <CorkButton onClick={this.save.bind(this)}>
                            Save Changes
                        </CorkButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default GeneralTab;