import React, {Component} from 'react'
import {PushScreen} from 'corky'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

class _PushScreen extends Component {
    render() {
        return (
            <PushScreen prefix="admin" picker={DateRangePicker} pushes={this.props.partner.pushes}
                        updatePushes={this.props.updatePushes}/>
        )
    }
}

export default _PushScreen;
