import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import EditTab from "./tabs/EditTab";
import {request, uploadImage} from "../../util/Util";
import CustomizationsTab from "./tabs/CustomizationsTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ProductScreen extends Component {
    render() {
        const {ID} = this.props.match.params;

        let product = this.props.partner.products.find((item) => item.ID === parseInt(ID));
        if (typeof product === "undefined") {
            return (
                <div/>
            )
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <a className="u-badge-v2--lg u-badge--bottom-right g-width-32 g-height-32 g-bg-primary--hover g-transition-0_3 g-mb-20 g-mr-20"
                                           style={{backgroundColor: "red"}} href="#!" onClick={() => {
                                            document.getElementById("uploadLogo").click();
                                        }}>
                                            <i className="hs-admin-pencil g-absolute-centered g-font-size-16 g-color-white"/>
                                        </a>

                                        <input id="uploadLogo" type="file" style={{display: "none"}}
                                               onChange={() => {
                                                   uploadImage("admin/image", document.getElementById("uploadLogo").files[0], (err, payload) => {
                                                       if (err) {
                                                           console.dir(err);
                                                           return;
                                                       }

                                                       request("products/" + product.ID, "PATCH", {LOGO: payload}, (err) => {
                                                           if (err) {
                                                               alert("ERROR");
                                                               return;
                                                           }

                                                           let products = this.props.partner.products;
                                                           let index = products.findIndex((item) => item.ID === parseInt(ID));
                                                           products.splice(index, 1, {
                                                               ...products[index],
                                                               LOGO: payload
                                                           });

                                                           setTimeout(() => {
                                                               this.props.updateProducts(products);
                                                           }, 1000);
                                                       });
                                                   });
                                               }}/>

                                        <img className="img-fluid rounded-circle"
                                             src={product.LOGO === null ?
                                                 "http://localhost:3000/assets/img-temp/130x130/img1.jpg" :
                                                 "https://s3.amazonaws.com/drip-drinks/" + product.LOGO}
                                             alt="Image description"/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {product.NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/product/" + ID} name="Edit Section"/>
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                                          url={"/product/" + ID + "/customizations"} name="Customizations"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/product/:ID'
                                   component={() => <EditTab {...this.props} />}/>
                            <Route exact path='/product/:ID/customizations'
                                   component={() => <CustomizationsTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductScreen));