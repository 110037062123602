import React, {Component} from 'react';
import Fancy from "../../modules/Fancy";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request} from "../../util/Util";
import moment from 'moment';
import News from "./modules/Product";

class ProductsScreen extends Component {
    state = {
        id: -1,
        name: "",
        price: "",
        category: null
    };

    createProduct() {
        let {name, price, category} = this.state;
        request("products", "POST", {NAME: name, PRICE: price, CATEGORY: category}, (err, id) => {
            if (err) {
                alert("ERROR");
                return;
            }

            this.props.updateProducts([...this.props.partner.products, {
                NAME: name,
                PRICE: price,
                CATEGORY_ID: category,
                CUSTOMIZATIONS: [],
                ID: id
            }]);

            this.setState({name: "", price: "", category: null});
            this.props.history.push("/product/" + id);
        });
    }

    render() {
        let {name, price, category} = this.state;
        let Newz = this.props.partner.products.map((item, i) => {
            return (
                <News key={i} item={item} {...this.props} onClick={() => {
                    this.props.history.push("product/" + item.ID)
                }}/>
            )
        });

        let Label = "Please select category";
        let Categories = this.props.partner.categories.map((item) => {
            if (category === item.ID) {
                Label = item.NAME;
            }

            return (
                <li data-original-index={1}><a tabIndex={0} className data-tokens="null"
                                               onClick={() => this.setState({category: item.ID})}
                                               role="option" aria-disabled="false"
                                               aria-selected="false"><span className="text">{item.NAME}</span><span
                    className="glyphicon glyphicon-ok check-mark"/></a></li>
            )
        });

        return (
            <div>
                <Fancy name="Create News" ref={(e) => this.fancy = e}
                       onClick={this.createProduct.bind(this)}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Name</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={name} onChange={(e) => this.setState({name: e.target.value})}
                               aria-invalid="false"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Category</label>

                        <div className="form-group u-select--v2 g-pos-rel g-brd-gray-light-v7 g-rounded-4 mb-0">
                            <span
                                className="g-pos-abs g-top-0 g-right-0 d-block g-width-40 h-100 opacity-0 g-opacity-1--success">
                                <i className="hs-admin-check g-absolute-centered g-font-size-default g-color-secondary"/>
                            </span>

                            <div className="btn-group bootstrap-select js-select u-select--v2-select w-100">
                                <button type="button"
                                        className={"btn dropdown-toggle btn-default " + (category === null ? "bs-placeholder" : "")}
                                        data-toggle="dropdown" role="button" title={Label}>
                                    <span className="filter-option pull-left">
                                        {Label}
                                    </span>&nbsp;
                                    <span className="bs-caret"><span className="caret"/></span>
                                </button>
                                <div className="dropdown-menu open" role="combobox">
                                    <ul className="dropdown-menu inner" role="listbox" aria-expanded="false">
                                        {Categories}
                                    </ul>
                                </div>
                            </div>

                            <i className="hs-admin-angle-down g-absolute-centered--y g-right-0 g-color-gray-light-v6 ml-auto g-mr-15"/>
                        </div>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Price (in cents)</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={price} onChange={(e) => this.setState({price: e.target.value})}
                               aria-invalid="false"/>
                    </div>
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">Products</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <a style={{backgroundColor: "#1cc9e4", color: "white"}} onClick={() => {
                                this.setState({
                                    id: -1,
                                    name: "",
                                    content: "",
                                    creator: "",
                                    logo: "",
                                    schedule: new Date()
                                }, () => {
                                    this.fancy.open();
                                });
                            }}
                               className="js-fancybox btn btn-xl u-btn-secondary g-width-160--md g-font-size-default g-ml-10"
                               data-src="#new-project-form" data-speed="350"
                               data-options="{&quot;touch&quot; : false}">New Product
                            </a>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        {Newz}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductsScreen;
