import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {COLORS, request, toDollars} from "../../../util/Util";
import Fancy from "./../../../modules/Fancy";

class PartnersTab extends Component {
    state = {phone: ""};

    add() {
        const {ID} = this.props.match.params;
        let {phone} = this.state;
        phone = phone.trim();

        if (phone[0] !== "1") {
            phone = "1" + phone;
        }

        request("admin/partner/" + ID, "POST", {PHONE: phone}).then((id) => {
            if (typeof id === "undefined") {
                alert("Partner already added");
                return;
            }

            this.props.updatePartners([...this.props.partner.partners, {
                LOCATION_ID: parseInt(ID),
                ACCOUNT_ID: id
            }])
        });
    }

    remove(id) {
        const {ID} = this.props.match.params;

        if (!window.confirm("Are you sure you want to remove this partner?")) {
            return;
        }

        request("admin/partner/" + ID + "/" + id, "DELETE", {}).then(() => {
            let partners = this.props.partner.partners;
            let index = partners.findIndex((item) => item.LOCATION_ID === parseInt(ID) && item.ACCOUNT_ID === id);
            partners.splice(index, 1);

            this.props.updatePartners(partners);
        });

    }

    render() {
        const {ID} = this.props.match.params;
        let {phone} = this.state;

        let location = this.props.partner.locations.find((item) => item.ID === parseInt(ID));
        if (typeof location === "undefined") {
            return (
                <div>Location not found</div>
            );
        }

        let Partners = this.props.partner.partners.filter((item) => item.LOCATION_ID === parseInt(ID)).map((item) => {
            let account = this.props.partner.members.find((_item) => _item.ID === item.ACCOUNT_ID);

            return (
                <div className="row g-mb-20">
                    <div className="col-md-11">
                        <div className="form-group g-pos-rel mb-0">
                            <input
                                className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-10"
                                type="text" placeholder="Price (in cents)" value={account.FULL_NAME}
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                         onClick={this.remove.bind(this, account.ID)}>
                        <i style={{color: "red", fontSize: 26}} className="fa fa-times show-cursor"/>
                    </div>
                </div>
            )
        });

        return (
            <div className="col-md-9">
                <div style={{float: "right", marginTop: 25, marginRight: 25}}>
                    <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold"}} onClick={() => {
                        this.fancy.show();
                    }}>
                        + Add Partner
                    </a>
                </div>

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <Fancy name="Add Partner" ref={(e) => this.fancy = e}
                           onClick={this.add.bind(this)}
                    >
                        <div className="g-mb-20">
                            <label className="g-mb-10" htmlFor="#bio">Phone</label>

                            <input name="firstName"
                                   className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={phone} onChange={(e) => {
                                this.setState({phone: e.target.value})
                            }} aria-invalid="false"/>
                        </div>
                    </Fancy>

                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Partners
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    {Partners}
                </div>
            </div>
        )
    }
}

export default PartnersTab;