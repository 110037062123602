const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function getURL() {
    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://localhost:6969/api/'
}

export function getShortURL() {
    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://127.0.0.1:6969/'
}

export function request(endpoint, method, body, callback) {
    return new Promise((resolve, reject) => {

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (typeof window.localStorage['TOKEN'] !== 'undefined') {
            headers['Authentication'] = window.localStorage['TOKEN']
        }

        if (body !== null) {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(body),
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        if (callback) {
                            callback(undefined, responseJson.data);
                        }

                        resolve(responseJson.data);
                    } else {
                        if (callback) {
                            callback(responseJson, responseJson.code);
                        }

                        reject(responseJson, responseJson.code);
                    }
                })
                .catch((error) => {
                    if (callback) {
                        callback(error, endpoint);
                    }

                    reject(error);
                });
        } else {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        if (callback) {
                            callback(undefined, responseJson.data);
                        }

                        resolve(responseJson.data);
                    } else {
                        if (callback) {
                            callback(responseJson, responseJson.code);
                        }

                        reject(responseJson, responseJson.code);
                    }
                })
                .catch((error) => {
                    if (callback) {
                        callback(JSON.stringify(error), endpoint);
                    }

                    reject(error);
                });
        }
    });
}

export function uploadImage(endpoint, image, callback) {
    var formData = new FormData();
    formData.append('file', image);

    let headers = {};
    if (typeof window.localStorage['TOKEN'] !== 'undefined') {
        headers['Authentication'] = window.localStorage['TOKEN']
    }

    fetch(getURL() + endpoint, {
        method: 'POST',
        body: formData,
        headers: headers
    }).then((response) => response.json()).then(function (response) {
        if (response.success === true) {
            callback(undefined, response.data);
        } else {
            callback(response);
        }
    });
}

export function formatDate(date) {
    if (typeof date.getMonth === "undefined") {
        date = new Date(date);
    }

    let str = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    if (date.getHours() >= 12) {
        str = "PM " + str
    } else {
        str = "AM " + str;
    }

    str = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + str;

    if (date.getHours() !== 0) {
        str = (date.getHours() > 12 ? date.getHours() % 12 : date.getHours()) + ":" + str;
    } else {
        str = "12:" + str;
    }

    return str;
}

let DEBUG = false;

export function log(message) {
    if (!DEBUG) {
        return;
    }

    alert(JSON.stringify(message));
}

export function toDollars(cents) {
    let str = "" + Math.ceil(cents);

    switch (str.length) {
        default:
            return str.substring(0, str.length - 2) + "." + str.substring(str.length - 2, str.length);
        case 0:
            return "0.00";
        case 1:
            return "0.0" + str;
        case 2:
            return "0." + str;
    }
}

export function parseImage(url) {
    if (url === null) {
        return "https://drip-drinks.s3.amazonaws.com/appicon.png";
    }

    if (url.startsWith("https")) {
        return url;
    }

    return "https://s3.amazonaws.com/drip-drinks/" + url;
}

export const COLORS = {
    DRIP_GOLD: "#b99438"
};
const AWS_S3_URL = "https://drip-drinks.s3.amazonaws.com";

export function getObjectImage(obj, key, backup) {
    if (obj.hasOwnProperty(key) === false || obj[key] === null) {
        return getUrl(backup);
    }

    return getUrl(obj[key]);
}

export function getUrl(url) {
    if (url.startsWith("http")) {
        return url;
    }

    return AWS_S3_URL + "/" + url;
}

export function isValidPhone(raw) {
    return phoneUtil.isValidNumber(phoneUtil.parse(raw, 'US'));
}

export function getFormattedPhone(number) {
    const formatter = new AsYouTypeFormatter("US");
    let formatted;

    number.replace(/-/g, '')
        .replace(/ /g, '')
        .split('')
        .forEach(n => formatted = formatter.inputDigit(n));

    return formatted;
}

export function formatPhone(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return match[0] + '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
}