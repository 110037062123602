import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {request} from "../../../util/Util";

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class EditTab extends Component {
    state = {
        product: null
    };

    componentWillMount() {
        const {ID} = this.props.match.params;

        this.setState({product: this.props.partner.products.find((item) => item.ID === parseInt(ID))})
    }

    edit() {
        const {ID} = this.props.match.params;

        request("products/" + ID, "PATCH", this.state.product, (err) => {
            if (err) {
                alert("INTERNAL_ERROR");
                return;
            }

            alert("DONE");
        })
    }

    render() {
        let {product} = this.state;

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <FormItem name="Name" value={product.NAME}
                                      onChange={(txt) => this.setState({product: {...product, NAME: txt}})}/>
                            <FormItem name="Price" value={product.PRICE}
                                      onChange={(txt) => this.setState({product: {...product, PRICE: txt}})}/>
                            <FormTextBox name="Description" value={product.DESCRIPTION}
                                         onChange={(txt) => this.setState({product: {...product, DESCRIPTION: txt}})}/>
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <div>
                            <button style={{backgroundColor: "#326489"}} onClick={this.edit.bind(this)}
                                    className="btn btn-md btn-xl--md u-btn-secondary g-width-160--md g-font-size-12 g-font-size-default--md g-mb-10">
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default EditTab;