import React, {Component} from 'react'
import {CorkTable} from "corky";
import 'bootstrap-daterangepicker/daterangepicker.css';

class TicketsScreen extends Component {
    render() {
        let data = this.props.partner.tickets.map((item) => {
            return {
                NAME: item.NAME,
                EMAIL: item.EMAIL,
                ID: item.ID,
                SUBJECT: item.SUBJECT,
                HANDLED: item.RESPONDED,
                MESSAGE: item.MESSAGE,
                LINK_ID: item.ID
            }
        });

        return (
            <div className="g-pa-20">
                <div className="row">
                    <CorkTable header={{
                        ID: {
                            NAME: "",
                            WIDTH: 10,
                            LINK: "/ticket/",
                            LINK_ID: "ID"
                        },
                        NAME: {
                            NAME: "Name",
                            WIDTH: 20
                        },
                        SUBJECT: {
                            NAME: "Subject",
                            WIDTH: 30
                        },
                        MESSAGE: {
                            NAME: "Content",
                            WIDTH: 30
                        },
                        HANDLED: {
                            NAME: "Handled",
                            WIDTH: 10
                        }
                    }} sort="ID" data={data} desc={true} title="Tickets"/>
                </div>
            </div>
        )
    }
}

export default TicketsScreen;
