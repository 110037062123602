import React, {Component} from 'react'
import Table from "../../modules/Table";
import {toDollars} from "../../util/Util";

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
class RedeemsScreen extends Component {
    render() {
        let {redeems} = this.props.partner;
        let locations = this.props.partner.locations.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let members = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});


        let Data = redeems.map((item) => {
            return {
                ID: item.ID,
                NAME: members[item.ACCOUNT_ID].FULL_NAME,
                ACCOUNT_ID: item.ACCOUNT_ID,
                LOCATION: locations[item.LOCATION_ID].NAME,
                LOCATION_ID: item.LOCATION_ID,
                AMOUNT: "$" + toDollars(item.PRICE),
                DATE_REDEEMED: moment(item.DATE_REDEEMED).format("hh:mma MM/DD/YY")
            };
        });

        return (
            <div>
                <Table header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 5
                    },
                    NAME: {
                        NAME: "Redeemer",
                        WIDTH: 30,
                        LINK: "/patron/",
                        LINK_ID: "ACCOUNT_ID"
                    },
                    LOCATION: {
                        NAME: "Location",
                        WIDTH: 30,
                        LINK: "/location/",
                        LINK_ID: "LOCATION_ID"
                    },
                    AMOUNT: {
                        NAME: "Balance",
                        WIDTH: 15
                    },
                    DATE_REDEEMED: {
                        NAME: "Date Redeemed",
                        WIDTH: 20
                    }
                }} sort="ID" data={Data} desc={true} title="Redeems"/>
                <div style={{marginBottom: 25, height: 25}}>
                    <br/>
                </div>
            </div>
        );
    }
}

export default RedeemsScreen;
