import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import GeneralTab from "./tabs/GeneralTab";
import PartnersTab from "./tabs/PartnersTab";
import RepsTab from "./tabs/RepsTab";
import OrdersTab from "./tabs/OrdersTab";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LocationScreen extends Component {
    render() {
        const {ID} = this.props.match.params;

        let location = this.props.partner.locations.find((item) => item.ID === parseInt(ID));
        if (typeof location === "undefined") {
            return (
                <div>Location not found</div>
            );
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <br/>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {location.NAME}
                                    </h3>

                                    <br/>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="fa fa-info" {...this.props}
                                                          url={"/location/" + ID} name="General Information"/>
                                        <SecondaryNavItem icon="fa fa-users" {...this.props}
                                                          url={"/location/" + ID + "/orders"} name="Orders"/>
                                        <SecondaryNavItem icon="fa fa-shopping-cart" {...this.props}
                                                          url={"/location/" + ID + "/partners"} name="Partners"/>
                                        <SecondaryNavItem icon="fa fa-users" {...this.props}
                                                          url={"/location/" + ID + "/reps"} name="Reps"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/location/:ID'
                                   component={() => <GeneralTab {...this.props} />}/>
                            <Route exact path='/location/:ID/partners'
                                   component={() => <PartnersTab {...this.props} />}/>
                            <Route exact path='/location/:ID/reps'
                                   component={() => <RepsTab {...this.props} />}/>
                            <Route exact path='/location/:ID/orders'
                                   component={() => <OrdersTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationScreen));