import React, {Component} from "react"
import Fancy from "../../../modules/Fancy";

import {FancyText, FancyDate, FancySelect} from "corky"
import {COLORS, request} from "../../../util/Util";


const FIELDS = {
    NAME: "company-name",
    LOGO: "company-logo",
    WHITE: "company-white",
};

class CreateEmployeeModal extends Component {
    constructor(props) {
        super(props);

        this.form = {};
    }

    create() {
        let form = this.form;

        let valid = true;
        for (let company of this.companyList) {
            if (company === null) {
                continue;
            }

            if (!company.isValid()) {
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        let {companies} = this.props.partner;
        let name = form[FIELDS.NAME], logo = form[FIELDS.LOGO], white = form[FIELDS.WHITE];
        request("admin/company", "POST", {
            NAME: name, LOGO: logo, WHITE: white
        }).then((id) => {
            companies.push({
                ID: id, NAME: name, LOGO: logo, WHITE_LABEL: white
            });

            this.fancy.close().then(() => {
                this.props.updateCompanies(companies);
            })
        });
    }

    open() {
        this.fancy.open()
    }

    render() {
        this.companyList = [];
        let form = this.form;

        return (
            <Fancy name="Create Company" onClick={this.create.bind(this)}
                   cancelText="Cancel" ref={(e) => this.fancy = e} buttonText="Create"
            >
                <form>
                    <div className="g-mb-20">
                        <FancyText form={form} id={FIELDS.NAME} type={FancyText.TYPES.TEXT} required={true}
                                   ref={(e) => this.companyList.push(e)} label="Company Name"/>
                    </div>

                    <div className="row g-mb-20">
                        <FancyText form={form} id={FIELDS.LOGO} type={FancyText.TYPES.TEXT} required={true}
                                   ref={(e) => this.companyList.push(e)} label="Logo" half={true}/>
                        <FancyText form={form} id={FIELDS.WHITE} type={FancyText.TYPES.TEXT} required={true}
                                   ref={(e) => this.companyList.push(e)} label="White Label" half={true}
                                    placeholder="1 FOR YES 0 FOR NO OK?"
                        />
                    </div>
                </form>
            </Fancy>
        )
    }
}

export default CreateEmployeeModal;