import React, {Component} from 'react';
import Noty from "../modules/Noty";

import {withRouter} from "react-router-dom";
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../actions/index'


function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Announcements extends Component {
    render() {
        let announcements = this.props.partner.announcements;
        let anSet = new Set();
        if (localStorage.getItem("announcements") !== null) {
            anSet = new Set(JSON.parse(localStorage.getItem("announcements")));
        }

        announcements = announcements.filter((item) => {
            return !anSet.has(item.ID)
        });

        let Announcements = announcements.map((item) => {
            return <Noty item={item} {...this.props} />
        });

        return (
            <div id="noty_layout__topRight" className="noty_layout">
                {Announcements}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Announcements));