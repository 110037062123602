import {
    UPDATE_REDEEM
} from "../actions/index";

let cloneObject = function (obj) {
    return JSON.parse(JSON.stringify(obj))
};

const defaultState = {
    account: {}
};

let newState = cloneObject(defaultState);

export default function (state = newState, action) {
    switch (action.type) {
        case "TT":
            return newState;
        case UPDATE_REDEEM:
            newState = cloneObject(state);

            newState.locations = action.locations;
            newState.drinks = action.drinks;
            newState.account = action.account;
            newState.redeemed = action.redeemed;

            return newState;
        default:
            return state
    }
}