import React, {Component} from 'react'
import {Link} from 'react-router-dom'

/*
List of dict where name
List of items

 */
class Table extends Component {
    state = {
        row: 0,
        rows: [],
        up: true,
        show: 10,
        search: "",
        sort: "",
        length: 0
    };

    componentWillMount() {
        let sort = this.props.sort;

        if (this.props.header[sort].SORT) {
            sort = this.props.header[sort].SORT
        }

        this.setState({sort: sort, up: !this.props.desc}, () => {
            this.reload();
        });
    }

    reload() {
        let copy = Array.from(this.props.data);
        let HeaderItems = Object.keys(this.props.header).map((item) => {
            return item
        });

        let SortItems = [...HeaderItems];
        if (typeof this.props.sortItems !== "undefined") {
            SortItems = [...SortItems, this.props.sortItems];
        }

        let search = this.state.search.trim().toLowerCase();
        if (search.length > 0) {
            copy = copy.filter((_item) => {
                for (let item of SortItems) {
                    if (typeof _item[item] === "undefined") {
                        continue;
                    }

                    if (("" + _item[item]).toLowerCase().startsWith(search)) {
                        return true;
                    }
                }
            });
        }

        if (this.state.sort in this.props.header && typeof this.props.header[this.state.sort].IGNORE !== "undefined") {
            copy = copy.filter((item) => {
                return item[this.state.sort] !== this.props.header[this.state.sort].IGNORE
            })
        }

        let length = copy.length;
        copy.sort((a, b) => {
            if (isNaN(a[this.state.sort]) || isNaN(b[this.state.sort])) {
                return ('' + a[this.state.sort]).localeCompare(b[this.state.sort]);
            }

            return a[this.state.sort] - b[this.state.sort];
        });

        if (!this.state.up) {
            copy.reverse();
        }

        let final = [];
        while (copy.length > 0) {
            final.push(copy.splice(0, this.state.show));
        }

        this.setState({rows: final, length: length});
    }

    render() {
        let {rows, row} = this.state;
        let HeaderItems = Object.keys(this.props.header).map((item) => {
            return item
        });

        let Widths = [];

        let Header = HeaderItems.map((item, i) => {
            Widths.push(
                <col key={i + HeaderItems.length} width={this.props.header[item].WIDTH + "%"}/>
            );

            return (
                <th key={i} onClick={() => {
                    let payload = {up: !this.state.up};

                    if (this.state.sort !== item || typeof this.props.header[item].SORT !== "undefined") {
                        payload.up = true;
                        payload.sort = item;

                        if (typeof this.props.header[item].SORT !== "undefined") {
                            if (this.props.header[item].SORT === this.state.sort) {
                                payload.up = false;
                            }

                            payload.sort = this.props.header[item].SORT
                        }
                    }

                    this.setState(payload, () => {
                        this.reload();
                    });
                }}>
                    {this.props.header[item].NAME}
                </th>
            )
        });

        let Row = <tr/>
        if (rows.length > 0) {
            Row = rows[row].map((row, _i) => {
                let RI = [];
                HeaderItems.forEach((item) => {
                    if (typeof this.props.header[item].LINK !== "undefined") {
                        if (this.props.header[item].LINK_ID !== "undefined") {
                            RI.push(
                                <Link style={{color: "#326489", fontWeight: "bold"}}
                                      to={this.props.header[item].LINK + row[this.props.header[item].LINK_ID]}>
                                    {row[item]}
                                </Link>
                            );

                        } else {
                            RI.push(<Link style={{color: "#326489", fontWeight: "bold"}}
                                          to={this.props.header[item].LINK + row.LINK_ID}>{row[item]}</Link>);
                        }
                    } else {
                        RI.push(row[item]);
                    }
                });

                return (
                    <tr key={_i}>
                        {
                            RI.map((item, i) => {
                                return (
                                    <td key={i}>
                                        {item}
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            });
        }

        let Next = <div/>;
        if (rows.length > row + 1) {
            Next = (
                <li className="list-inline-item">
                    <a id="datatablePagination1Next"
                       className="u-pagination-v1__item u-pagination-v1-2 g-brd-gray-light-v7 g-brd-lightblue-v3--hover g-rounded-4 g-py-8 g-px-12"
                       href="#!" aria-label="Next" onClick={() => this.setState({row: row + 1})}><span
                        className="g-line-height-1 g-valign-middle"
                        aria-hidden="true"><i className="hs-admin-angle-right"/></span><span
                        className="sr-only">Next</span>
                    </a>
                </li>
            );
        }

        let Last = <div/>
        if (row > 0) {
            Last = (
                <li className="list-inline-item">
                    <a id="datatablePagination1Prev" onClick={() => this.setState({row: row - 1})}
                       className="u-pagination-v1__item u-pagination-v1-2 g-brd-gray-light-v7 g-brd-lightblue-v3--hover g-rounded-4 g-py-8 g-px-12"
                       href="#!" aria-label="Previous"><span
                        className="g-line-height-1 g-valign-middle"
                        aria-hidden="true"><i className="hs-admin-angle-left"></i></span><span
                        class="sr-only">Prev</span>
                    </a>
                </li>
            );
        }

        let Slots = [];
        if (row < 3 || rows.length < 6) {
            for (let i = 0; i < Math.min(rows.length, 5); i++) {
                Slots.push(
                    <li className="list-inline-item g-hidden-sm-down" key={i}>
                        <a id="datatablePagination212" onClick={() => this.setState({row: i})}
                           className={"u-pagination-v1__item u-pagination-v1-2 g-bg-lightblue-v3--active g-color-white--active g-brd-gray-light-v7 g-brd-lightblue-v3--hover g-brd-lightblue-v3--active g-rounded-4 g-py-8 g-px-15" + (i === row ? " active" : "")}>{i + 1}</a>
                    </li>
                )
            }
        } else if (row + 3 >= rows.length) {
            for (let i = rows.length - 5; i < rows.length; i++) {
                Slots.push(
                    <li className="list-inline-item g-hidden-sm-down">
                        <a id="datatablePagination212" onClick={() => this.setState({row: i})}
                           className={"u-pagination-v1__item u-pagination-v1-2 g-bg-lightblue-v3--active g-color-white--active g-brd-gray-light-v7 g-brd-lightblue-v3--hover g-brd-lightblue-v3--active g-rounded-4 g-py-8 g-px-15" + (i === row ? " active" : "")}>{i + 1}</a>
                    </li>
                )
            }
        } else {
            for (let i = row - 2; i <= row + 2; i++) {
                Slots.push(
                    <li className="list-inline-item g-hidden-sm-down" key={i}>
                        <a id="datatablePagination212" onClick={() => this.setState({row: i})}
                           className={"u-pagination-v1__item u-pagination-v1-2 g-bg-lightblue-v3--active g-color-white--active g-brd-gray-light-v7 g-brd-lightblue-v3--hover g-brd-lightblue-v3--active g-rounded-4 g-py-8 g-px-15" + (i === row ? " active" : "")}>{i + 1}</a>
                    </li>
                )
            }
        }

        let ActualHeader = (
            <div className="media-md align-items-center g-mb-30">
                <div className="d-flex g-mb-15 g-mb-0--md"><h3
                    className="g-font-weight-400 g-font-size-16 g-color-black mb-0">{this.props.title}</h3></div>

                <div className="media d-md-flex align-items-center ml-auto">
                    <div className="d-flex align-items-center"><span
                        className="g-hidden-sm-down g-color-gray-dark-v6 g-mr-12">Show:</span>
                        <div className="u-select--v1 g-pr-20">
                            <select id="datatableEntries1" defaultValue={10} onChange={(e) => {
                                this.setState({show: e.target.value, row: 0}, () => {
                                    this.reload();
                                });
                            }}
                                    className="js-select u-select--v1-select w-100">
                                <option className="makeSureItWorks" onClick={() => {
                                    this.setState({show: 5});
                                    this.reload();
                                }}
                                        data-content="<span class=&quot;d-flex align-items-center g-line-height-1_2 g-color-black&quot;>5 Entries</span>"
                                        value="5">5 Entries
                                </option>
                                <option className="makeSureItWorks" onClick={() => {
                                    this.setState({show: 10});
                                    this.reload();
                                }}
                                        data-content="<span class=&quot;d-flex align-items-center g-line-height-1_2 g-color-black&quot;>10 Entries</span>"
                                        value="10">10 Entries
                                </option>
                                <option className="makeSureItWorks" onClick={() => {
                                    this.setState({show: 15});
                                    this.reload();
                                }}
                                        data-content="<span class=&quot;d-flex align-items-center g-line-height-1_2 g-color-black&quot;>15 Entries</span>"
                                        value="15">15 Entries
                                </option>
                                <option className="makeSureItWorks" onClick={() => {
                                    this.setState({show: 20});
                                    this.reload();
                                }}
                                        data-content="<span class=&quot;d-flex align-items-center g-line-height-1_2 g-color-black&quot;>20 Entries</span>"
                                        value="20">20 Entries
                                </option>
                                <option className="makeSureItWorks" onClick={() => {
                                    this.setState({show: 25});
                                    this.reload();
                                }}
                                        data-content="<span class=&quot;d-flex align-items-center g-line-height-1_2 g-color-black&quot;>25 Entries</span>"
                                        value="25">25 Entries
                                </option>
                            </select><i
                            className="hs-admin-angle-down g-absolute-centered--y g-right-0 g-color-gray-light-v6"></i>
                        </div>
                    </div>
                    <div className="d-flex g-ml-15 g-ml-55--md">
                        <div className="input-group g-pos-rel g-width-320--md">
                            <input id="datatableSearch1"
                                   className="form-control g-font-size-default g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-20 g-pl-20 g-pr-50 g-py-10"
                                   type="text"
                                   placeholder="Search for name" onChange={(e) => {
                                this.setState({search: e.target.value}, () => {
                                    this.reload();
                                });
                            }}/>
                            <button
                                className="btn g-pos-abs g-top-0 g-right-0 g-z-index-2 g-width-60 h-100 g-bg-transparent g-font-size-16 g-color-lightred-v2 g-color-lightblue-v3--hover rounded-0"
                                type="submit"><i className="hs-admin-search g-absolute-centered"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

        if (this.props.noHeader) {
            ActualHeader = <div/>
        }

        let padding = "g-pa-20", padding2 = "col g-ml-50 g-ml-0--lg g-overflow-hidden";
        if (this.props.noPadding) {
            padding = "";
            padding2 = "";
        }

        if (this.props.data.length === 0) {
            Row = (
                <tr>
                    <td colSpan={HeaderItems.length}>{
                        "emptyMsg" in this.props ? this.props.emptyMsg : "No Data"
                    }</td>
                </tr>
            );
        }

        return (
            <div className={padding2}>
                <div className={padding}>
                    {ActualHeader}

                    <table className="table u-table--v3 g-color-black">
                        <colgroup>
                            {Widths}
                        </colgroup>
                        <thead>
                        <tr>
                            {Header}
                        </tr>
                        </thead>
                        <tbody>
                        {Row}
                        </tbody>
                    </table>

                    <div className="media align-items-center g-mb-45" style={{marginTop: 15}}>
                        <div className="media-body">
                            Showing {Math.min(this.state.show, this.state.length)} of {this.state.length} Entries
                        </div>

                        <nav className="d-flex ml-auto" aria-label="Page Navigation">
                            <ul className="list-inline text-right mb-0">
                                {Last}
                                {Slots}
                                {Next}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default Table;
