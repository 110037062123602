import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import {COLORS, request, toDollars} from "../../../util/Util";

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class GeneralTab extends Component {
    refund() {
        let {order} = this.props;

        request(`admin/refund/${order.ID}/${order.LOCATION_ID}`, "POST", {}).then(() => {
            alert("Order has been refunded");
        }).catch(() => {
            alert("Error refunding order")
        });
    }

    render() {
        let {order} = this.props;
        let tip = this.props.partner.tips.find((item) => item.ORDER_ID === order.ID);

        let completed = "In Progress";
        if (order.DATE_COMPLETED !== null) {
            completed = moment(new Date(order.DATE_COMPLETED)).format("hh:mm a ddd MM/DD/YYYY");
        }

        let tipAmount = 0;
        if (typeof tip !== "undefined") {
            tipAmount = tip.AMOUNT;
        }

        let refund = (
            <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold", float: "right"}}
               onClick={this.refund.bind(this)}>
                Refund Order
            </a>
        );

        if (order.DATE_SENT + (1000 * 60 * 60 * 12) < new Date().getTime() || order.REFUND_ID !== null) {
            refund = <div/>;
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information

                            {refund}
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormItem disabled={true} name="Name" value={order.NAME}/>
                    <FormItem disabled={true} name="Sent"
                              value={moment(new Date(order.DATE_SENT)).format("hh:mma ddd MM/DD/YYYY")}/>
                    <FormItem disabled={true} name="Completed" value={completed}/>

                    <header style={{marginTop: 40}}>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            Order Numbers
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>
                    <FormItem disabled={true} name="Subtotal" value={"$" + toDollars(order.SUBTOTAL)}/>
                    <FormItem disabled={true} name="Taxes" value={"$" + toDollars(order.TAXES)}/>
                    <FormItem disabled={true} name="Tip" value={"$" + toDollars(tipAmount)}/>
                    <FormItem disabled={true} name="Processing"
                              value={"$" + toDollars(Math.ceil(order.SUBTOTAL * 0.079))}/>
                    <FormItem disabled={true} name="Takeaway"
                              value={"$" + toDollars(Math.ceil(order.SUBTOTAL * 0.079 + order.PROCESSING))}/>
                </div>
            </div>
        )
    }
}

export default GeneralTab;