import React, {Component} from 'react'
import Table from "../../../modules/Table";
import CorkButton from "../../../modules/CorkButton";
import {FancyText, CorkTable} from "corky"
import {request} from "../../../util/Util";

let moment = require("moment");

class PatronsTab extends Component {
    edit() {
        let {patrons} = this.props.partner;
        request("admin/patron/" + this.patron.state.value, "POST", {POINTS: this.points.state.value}).then(() => {
            alert("DONE BABE");

            let index = patrons.findIndex((item) => item.ID === this.patron.state.value);
            patrons.splice(index, 1, {...patrons[index], POINTS: this.points.state.value});
            this.props.updatePatrons(patrons);
        }).catch(() => {
            alert("NOO");
        })
    }

    render() {
        const {ID} = this.props.match.params;
        let {patrons, locations} = this.props.partner;
        let user = {FIRST_NAME: "Unknown", LAST_NAME: "User"};

        let members = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        if (typeof members[ID] !== "undefined") {
            user = members[ID];
        }

        let locationIds = locations.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let Data = patrons.filter((item) => item.ACCOUNT_ID === user.ID).map((item) => {
            return {
                ID: item.ID,
                LOCATION: locationIds[item.LOCATION_ID].NAME,
                LOCATION_ID: item.LOCATION_ID,
                POINTS: item.POINTS,
                FIRST_REDEEM: moment(item.DATE_CREATED).format("hh:mm a DD/MM/YY"),
                LAST_REDEEM: moment(item.DATE_CREATED).format("hh:mm a DD/MM/YY"),
                onEdit: () => {
                    this.patron.setState({value: item.ID});
                    this.points.setState({value: item.POINTS});
                }
            }
        });

        return (
            <div>
                <CorkTable header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 5,
                        LINK: "/order/",
                        LINK_ID: "ID"
                    },
                    LOCATION: {
                        NAME: "Location",
                        LINK: "/location/",
                        LINK_ID: "LOCATION_ID",
                        WIDTH: 30,
                    },
                    POINTS: {
                        NAME: "Points",
                        WIDTH: 10,
                    },
                    FIRST_REDEEM: {
                        NAME: "First Seen",
                        WIDTH: 25,
                    },
                    LAST_REDEEM: {
                        NAME: "Last Seen",
                        WIDTH: 25,
                    },
                }} sort="ID" data={Data} desc={true} edit={true} title="Patrons"/>

                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <form className="js-validate" onSubmit={(e) => e.preventDefault()}>
                        <header>
                            <h2 style={{marginBottom: 12}}
                                className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                General information
                            </h2>

                            <FancyText ref={(e) => this.patron = e} label="Patron ID" />
                            <FancyText ref={(e) => this.points = e} label="Points" />
                        </header>

                        <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                        <div className="row">
                            <CorkButton onClick={this.edit.bind(this)}>
                                Save Changes
                            </CorkButton>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PatronsTab;