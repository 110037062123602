import React, {Component} from 'react';
import Fancy from "../../modules/Fancy";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {formatDate, request, uploadImage} from "../../util/Util";
import moment from 'moment';
import News from "./modules/News";

class NewsScreen extends Component {
    state = {
        id: -1,
        schedule: new Date(),
        title: "",
        content: "",
        subtitle: "",
        logo: "",
        showSchedule: false
    };

    uploadLogo() {
        return new Promise((resolve, reject) => {
            uploadImage("admin/image", document.getElementById("uploadLogo").files[0], (err, payload) => {
                if (err) {
                    return reject();
                }

                resolve(payload);
            });
        });
    }

    createNews() {
        let {title, subtitle, content, logo, schedule, showSchedule} = this.state;

        if (title.length === 0 || subtitle.length === 0 || content.length === 0) {
            alert("Please fill out all fields");
            return;
        }

        if (logo.length === 0) {
            alert("Please upload a logo or input a link to a photo");
            return;
        }

        request("admin/news", "POST", {
            TITLE: title,
            SUBTITLE: subtitle,
            CONTENT: content,
            LOGO: logo,
            RELEASE: showSchedule ? schedule.getTime() : new Date().getTime()
        }, (err, payload) => {
            if (err) {
                alert("Internal Error");
                return;
            }

            this.setState({
                title: "",
                subtitle: "",
                content: "",
                logo: "",
                schedule: new Date(),
                showSchedule: false
            }, () => {
                this.fancy.close();

                this.props.updateNews([...this.props.partner.news, {
                    ID: payload,
                    TITLE: title,
                    SUBTITLE: subtitle,
                    CONTENT: content,
                    LOGO: logo,
                    RELEASE: showSchedule ? schedule.getTime() : new Date().getTime(),
                    DATE_POSTED: new Date().getTime()
                }]);
            });
        });
    }

    render() {
        let {id, title, subtitle, content, logo, showSchedule} = this.state;
        let Newz = this.props.partner.news.slice().reverse().map((item, i) => {
            return (
                <News key={i} item={item} {...this.props} onClick={() => this.props.history.push("/post/" + item.ID)}/>
            )
        });

        let Schedule = (
            <div
                className="form-group d-flex align-items-center justify-content-between g-mb-20">
                <label className="g-mb-10" for="#bio">Schedule For</label>

                <DateRangePicker singleDatePicker drops="up" timePicker style={{zIndex: 1000}}
                                 ref={(e) => this.pick = e}
                                 onApply={(_, e) => this.setState({schedule: e.startDate.toDate()})}
                                 minDate={moment(new Date())}
                                 startDate={this.state.schedule}>
                    <div
                        className="u-datepicker-right u-datepicker--v3 g-pos-rel w-100 g-cursor-pointer g-brd-around g-brd-gray-light-v7 g-rounded-4">
                        <input
                            className="js-range-datepicker g-bg-transparent g-font-size-12 g-font-size-default--md g-color-gray-dark-v6 g-pr-80 g-pl-15 g-py-9 flatpickr-input"
                            type="text" placeholder="Select Date" data-rp-wrapper="#datepickerWrapper"
                            value={formatDate(this.state.schedule)}
                            data-rp-date-format="d/m/Y" readonly="readonly"/>
                    </div>
                </DateRangePicker>
            </div>
        );

        if (!showSchedule) {
            Schedule = <div/>
        }

        return (
            <div>
                <Fancy name="Create News" ref={(e) => this.fancy = e}
                       onClick={() => id === -1 ? this.createNews() : this.editNews()}
                >
                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Title</label>

                        <input name="firstName"
                               className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                               type="text" value={title} onChange={(e) => this.setState({title: e.target.value})}
                               aria-invalid="false" placeholder="New Drinks Coming Soon"/>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Subtitle</label>

                        <div className="form-group mb-0">
                            <textarea id="bio" value={subtitle}
                                      onChange={(e) => this.setState({subtitle: e.target.value})}
                                      className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                      rows="2" placeholder="Since it's the start of april we are adding new drinks!"/>
                        </div>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Logo</label>

                        <div style={{flexDirection: "row"}}>
                            <input name="firstName" style={{flex: 1}}
                                   className="form-control h-100 form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-brd-primary--error g-rounded-4 g-px-20 g-py-12"
                                   type="text" value={logo} onChange={(e) => this.setState({logo: e.target.value})}
                                   aria-invalid="false" placeholder="https://dripdrinks.co/myimage.png"/>

                            <button onClick={() => {
                                document.getElementById("uploadLogo").click();
                            }}>
                                Upload Logo
                            </button>

                            <input id="uploadLogo" type="file" style={{display: "none"}}
                                   onChange={() => this.uploadLogo().then((data) => {
                                       this.setState({logo: data});
                                   }).catch(() => {
                                       alert("Image too large. Please downsize it.");
                                   })}/>
                        </div>
                    </div>

                    <div className="g-mb-20">
                        <label className="g-mb-10" htmlFor="#bio">Content</label>

                        <div className="form-group mb-0">
                            <textarea id="bio" value={content}
                                      onChange={(e) => this.setState({content: e.target.value})}
                                      className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                      rows="5"/>
                        </div>
                    </div>

                    <div onClick={() => this.setState({showSchedule: !showSchedule})}
                         className="form-group d-flex align-items-center justify-content-between g-mb-20 show-cursor">
                        <span>Schedule in advance</span>
                        <label className="u-check mb-0 g-ml-10">
                            <input className="g-hidden-xs-up g-pos-abs g-top-0 g-right-0"
                                   type="checkbox" checked={showSchedule} onChange={() => {}}
                                   />
                            <div
                                className="u-check-icon-radio-v8 u-check-icon--md u-check-icon--lightblue-v3">
                                <i className="fa"/>
                            </div>
                        </label>
                    </div>

                    {Schedule}
                </Fancy>

                <div className="g-pa-20">
                    <div className="media">
                        <div className="d-flex align-self-center">
                            <h1 className="g-font-weight-300 g-font-size-28 g-color-black mb-0">News</h1>
                        </div>

                        <div className="media-body align-self-center text-right">
                            <a style={{backgroundColor: "#1cc9e4", color: "white"}} onClick={() => {
                                this.fancy.open();
                            }}
                               className="js-fancybox btn btn-xl u-btn-secondary g-width-160--md g-font-size-default g-ml-10"
                               data-src="#new-project-form" data-speed="350"
                               data-options="{&quot;touch&quot; : false}">New Post
                            </a>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        {Newz}
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsScreen;
