import React, {Component} from 'react'
import {toDollars} from "../../util/Util";
import {CorkTable} from "corky"

let moment = require("moment");

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
class OrdersScreen extends Component {
    constructor(props) {
        super(props);
    }

    getOrderType(item) {
        switch (item.TRANSACTION_TYPE) {
            default:
                return "unknown"
            case 0:
                return "drip card"
            case 1:
                return "card"
            case 2:
                return "cash"
            case 3:
                return "tab"
            case 4:
                return "gift card"
            case 5:
                return "other"
            case 6:
                return "invoice"
            case 7:
                return "reward"
            case 8:
                return "split"
        }
    }

    getPlatform(item) {
        switch (item.PLATFORM) {
            default:
            case 0:
                return "mobile"
            case 1:
                return "register"
            case 2:
                return "kiosk"
            case 3:
                return "register"
        }
    }


    render() {
        let date = new Date().getTime();
        let memberDict = {}, drinksDict = {};
        let drinks = this.props.partner.orders;
        let members = this.props.partner.members;
        let locations = this.props.partner.locations.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        for (let i = 0; i < members.length; i++) {
            memberDict[members[i]['ID']] = members[i];
        }

        for (let i = 0; i < drinks.length; i++) {
            let drink = drinks[i];

            if (!drinksDict.hasOwnProperty(drink['ACCOUNT_ID'])) {
                drinksDict[drink['ACCOUNT_ID']] = [];
            }

            drinksDict[drink['ACCOUNT_ID']].push(drink);
        }

        let tips = this.props.partner.tips.reduce((dict, item) => {
            dict[item.ORDER_ID] = item;
            return dict;
        }, {});

        let Rows = drinks.map((item, i) => {
            let user = memberDict[item.ACCOUNT_ID];

            let Re = moment(new Date(item.DATE_SENT));
            let tip = tips[item.ID] ? tips[item.ID].AMOUNT : 0;

            let completed = "?";
            if (item.DATE_COMPLETED !== null) {
                completed = moment(new Date(item.DATE_COMPLETED)).format("h:mm a");
            }

            return {
                ID: item.ID,
                LINK_ID: item.ACCOUNT_ID,
                NAME: user ? user.FULL_NAME : item.NAME,
                SUB: toDollars(item.SUBTOTAL),
                TAXES: toDollars(item.TAXES),
                TIP: toDollars(tip),
                PROCESSING: toDollars(item.PROCESSING),
                TOTAL: toDollars(item.TOTAL),
                DATE: Re.format('h:mm a ddd MMM DD'),
                LOCATION: locations[item.LOCATION_ID].NAME,
                LOCATION_ID: item.LOCATION_ID,
                FULL: completed,
                TIMESTAMP: item.DATE_SENT,
                ACCOUNT_ID: item.ACCOUNT_ID,
                TYPE: this.getOrderType(item),
                PLATFORM: this.getPlatform(item)
            };
        });

        return (
            <div>
                <CorkTable header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 3,
                        LINK: "/order/",
                        LINK_ID: "ID"
                    },
                    NAME: {
                        NAME: "Name",
                        WIDTH: 10,
                        LINK: "/order/",
                        LINK_ID: "ID"
                    },
                    LOCATION: {
                        NAME: "Location",
                        WIDTH: 10,
                        LINK: "/location/",
                        LINK_ID: "LOCATION_ID"
                    },
                    TOTAL: {
                        NAME: "Total",
                        WIDTH: 5
                    },
                    PROCESSING: {
                        NAME: "Takeaway",
                        WIDTH: 5
                    },
                    PLATFORM: {
                        NAME: "Platform",
                        WIDTH: 5
                    },
                    TYPE: {
                        NAME: "Order Type",
                        WIDTH: 5
                    },
                    DATE: {
                        NAME: "Date",
                        WIDTH: 10,
                        SORT: "TIMESTAMP"
                    }
                }} sort="DATE" data={Rows} desc={true} title="Orders"/>
                <div style={{marginBottom: 25, height: 25}}>
                    <br/>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);
        let minutes = jsDate.getMinutes();

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + minutes + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + minutes + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}

export default OrdersScreen;
