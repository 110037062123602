import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../../actions/index'

import {
    withRouter
} from 'react-router-dom'
import GeneralTab from "./tabs/GeneralTab";
import OrdersTab from "./tabs/OrdersTab";
import SecondaryNavItem from "../../modules/SecondaryNavItem";
import AlterTab from "./tabs/AlterTab";
import PatronsTab from "./tabs/PatronsTab";
import {getObjectImage} from "../../util/Util";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PatronScreen extends Component {
    state = {
        active: 0,
        user: {FIRST_NAME: "Unknown", LAST_NAME: "User"}
    };

    render() {
        const {ID} = this.props.match.params;
        let user = {FULL_NAME: "Unknown User"};

        let members = this.props.partner.members.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        if (typeof members[ID] !== "undefined") {
            user = members[ID];
        } else {
            return (
                <div />
            )
        }

        return (
            <div className="col g-ml-45 g-ml-0--lg g-pb-65--md">
                <div className="g-pa-20">
                    <div className="row">
                        <div className="col-md-3 g-mb-30 g-mb-0--md">
                            <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                                <section className="text-center g-mb-30 g-mb-50--md">
                                    <div className="d-inline-block g-pos-rel g-mb-20">
                                        <img className="img-fluid rounded-circle"
                                             src={getObjectImage(user, "LOGO", "appicon.png")}
                                             alt="Image description"/>
                                    </div>

                                    <h3 className="g-font-weight-300 g-font-size-20 g-color-black mb-0">
                                        {user.FULL_NAME}
                                    </h3>
                                </section>

                                <section>
                                    <ul className="list-unstyled mb-0">
                                        <SecondaryNavItem icon="hs-admin-user" {...this.props}
                                              url={"/patron/" + ID} name="General Information"/>
                                        <SecondaryNavItem icon="fa fa-coffee" {...this.props}
                                              url={"/patron/" + ID + "/orders"} name="Orders"/>
                                        <SecondaryNavItem icon="fa fa-user-edit" {...this.props}
                                                          url={"/patron/" + ID + "/alter"} name="Alter Info"/>
                                        <SecondaryNavItem icon="fa fa-trophy" {...this.props}
                                                          url={"/patron/" + ID + "/patrons"} name="Patrons"/>
                                    </ul>
                                </section>
                            </div>
                        </div>

                        <Switch>
                            <Route exact path='/patron/:ID'
                                   component={() => <GeneralTab {...this.props} />}/>
                            <Route exact path='/patron/:ID/orders'
                                   component={() => <OrdersTab {...this.props} />}/>
                            <Route exact path='/patron/:ID/alter'
                                   component={() => <AlterTab {...this.props} />}/>
                            <Route exact path='/patron/:ID/patrons'
                                   component={() => <PatronsTab {...this.props} />}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatronScreen));