import React, {Component} from 'react'
import Table from "../../modules/Table";
import {formatPhone, getFormattedPhone} from "../../util/Util";

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

class PatronsScreen extends Component {
    render() {
        let date = new Date().getTime();
        let drinksDict = {};
        let drinks = this.props.partner.orders;
        for (let i = 0; i < drinks.length; i++) {
            let drink = drinks[i];

            if (!drinksDict.hasOwnProperty(drink['ACCOUNT_ID'])) {
                drinksDict[drink['ACCOUNT_ID']] = [];
            }

            drinksDict[drink['ACCOUNT_ID']].push(drink);
        }

        let locations = this.props.partner.locations.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        let orders = this.props.partner.orders.slice().reverse();
        let Rows = this.props.partner.members.map((item, i) => {
            let userDrinks = drinksDict[item.ID];

            if (typeof userDrinks === "undefined") {
                return {
                    ID: item.ID,
                    NAME: item.FULL_NAME,
                    PHONE: getFormattedPhone(item.PHONE),
                    RAW_PHONE: item.PHONE,
                    TOTAL: 0,
                    MONTHLY: 0,
                    FAVORITE: "None",
                    LOCATION: "None",
                    LINK_ID: item.ID
                }
            }

            let lastMonth = userDrinks.filter((item) => {
                return item.DATE_SENT > date - (1000 * 60 * 60 * 24 * 31);
            });

            let drinkDates = [0, 0, 0, 0, 0, 0, 0];
            for (let i = 0; i < userDrinks.length; i++) {
                drinkDates[new Date(userDrinks[i].DATE_SENT).getDay()] += 1;
            }

            let order = orders.find((_item) => _item.ACCOUNT_ID === item.ID);

            return {
                ID: item.ID,
                NAME: item.FULL_NAME,
                TOTAL: userDrinks.length,
                MONTHLY: lastMonth.length,
                FAVORITE: PatronsScreen.getHotDay(drinkDates),
                LINK_ID: item.ID,
                LOCATION: locations[order.LOCATION_ID].NAME,
                LOCATION_ID: order.LOCATION_ID,
                PHONE: getFormattedPhone(item.PHONE),
                RAW_PHONE: item.PHONE
            };
        });

        return (
            <div>
                <Table header={{
                    ID: {
                        NAME: "Identifier",
                        WIDTH: 10,
                        LINK: "/patron/",
                        LINK_ID: "ID"
                    },
                    NAME: {
                        NAME: "Patron",
                        WIDTH: 25,
                        LINK: "/patron/",
                        LINK_ID: "ID"
                    },
                    LOCATION: {
                        NAME: "Last Location",
                        WIDTH: 20,
                        LINK: "/location/",
                        LINK_ID: "LOCATION_ID"
                    },
                    PHONE: {
                        NAME: "Phone",
                        LENGTH: 15,
                        SORT: "RAW_PHONE"
                    },
                    TOTAL: {
                        NAME: "Total Orders",
                        WIDTH: 10
                    },
                    MONTHLY: {
                        NAME: "Monthly Orders",
                        WIDTH: 10
                    },
                    FAVORITE: {
                        NAME: "Favorite Day",
                        WIDTH: 10
                    },
                }} sort="ID" data={Rows} desc={true} title="Members"/>
                <div style={{marginBottom: 25, height: 25}}>
                    <br/>
                </div>
            </div>
        );
    }

    static toDate(utc) {
        let jsDate = new Date(utc);

        let time = '';
        if (jsDate.getHours() > 12) {
            time = jsDate.getHours() % 12 + ':' + jsDate.getMinutes() + ' PM on ';
        } else {
            if (jsDate.getHours() === 0) {
                time = '12:' + jsDate.getMinutes() + ' AM on ';
            } else {
                time = jsDate.getHours() + ':' + jsDate.getMinutes() + ' AM on ';
            }
        }

        return time + jsDate.toDateString()
    }

    static getHotDay(array) {
        let most = array[0], value = [0];
        for (let i = 1; i < array.length; i++) {
            if (array[i] > most) {
                value = [i];
                most = array[i];
            } else if (array[i] === most) {
                value.push(i);
            }
        }

        if (most === 0) {
            return "NaN";
        }

        return value.map((item) => {
            return DAYS[item];
        }).join("/");
    }
}

export default PatronsScreen;
