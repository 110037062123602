import React, {Component} from 'react'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as Actions from '../../../actions'
import {
    withRouter
} from 'react-router-dom'
import {formatDate, request} from "../../../util/Util";

function mapStateToProps(state) {
    return {partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}


class TicketScreen extends Component {
    deleteTicket() {
        if (!window.confirm("Are you sure you want to delete this ticket?")) {
            return;
        }

        const {ID} = this.props.match.params;
        request("admin/ticket/" + ID, "DELETE", {}, (err) => {
            if (err) {
                console.dir(err);
                return;
            }

            let tickets = this.props.partner.tickets;
            tickets.some((item, i) => {
                if (item.ID === parseInt(ID)) {
                    tickets.splice(i, 1);
                    return true;
                }

                return false;
            });

            this.props.updateTickets(tickets);
            this.props.history.goBack();
        })
    }

    toggleStatus() {
        const {ID} = this.props.match.params;

        request("admin/ticket/" + ID + "/status", "POST", {}, (err) => {
            if (err) {
                console.log("ERROR");
                return;
            }

            let tickets = this.props.partner.tickets;
            tickets.some((item, i) => {
                if (item.ID === parseInt(ID)) {
                    tickets.splice(i, 1, {...item, RESPONDED: item.RESPONDED === 0 ? 1 : 0});
                    return true;
                }
            });

            this.props.updateTickets(tickets);
        });
    }

    render() {
        const {ID} = this.props.match.params;
        let tickets = this.props.partner.tickets.reduce((dict, item) => {
            dict[item.ID] = item;
            return dict;
        }, {});

        if (typeof ID === "undefined" || !(ID in tickets)) {
            return (
                <div>
                    You done fucked up boy
                </div>
            );
        }

        let {NAME, EMAIL, PHONE, SUBJECT, MESSAGE, RESPONDED, DATE_SENT} = tickets[ID];

        return (
            <div className="g-pa-20">
                <div className="row">
                    <div className="col-md-12">
                        <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-30--md">
                            <header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Contact Info
                                </h2>

                                <div className="media-body align-self-center text-right">
                                    <a
                                        className="js-fancybox btn btn-xl u-btn-secondary g-width-160--md g-font-size-default g-ml-10"
                                        data-src="#new-project-form" data-speed={350}
                                        data-options="{&quot;touch&quot; : false}" onClick={() => this.deleteTicket()}
                                        style={{backgroundColor: 'rgb(202,52,51)', color: 'white'}}>
                                        Delete
                                    </a>

                                    <a
                                        className="js-fancybox btn btn-xl u-btn-secondary g-width-160--md g-font-size-default g-ml-10"
                                        data-src="#new-project-form" data-speed={350}
                                        data-options="{&quot;touch&quot; : false}" onClick={() => this.toggleStatus()}
                                        style={{backgroundColor: 'rgb(28, 201, 228)', color: 'white'}}>
                                        {RESPONDED === 1 ? "Mark Unread" : "Mark Read"}
                                    </a>
                                </div>
                            </header>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Name</label>
                                <div className="form-group mb-0">
                                    <input type="text" id="bio"
                                           className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                           rows={5} value={NAME} disabled/>
                                </div>
                            </div>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Date Sent</label>
                                <div className="form-group mb-0">
                                    <input type="text" id="bio"
                                           className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                           value={DATE_SENT === null ? "Unknown" : formatDate(DATE_SENT)} disabled/>
                                </div>
                            </div>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Email</label>
                                <div className="form-group mb-0">
                                    <input type="text" id="bio"
                                           className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                           rows={5} value={EMAIL} disabled/>
                                </div>
                            </div>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Phone Number</label>
                                <div className="form-group mb-0">
                                    <input type="text" id="bio"
                                           className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                           rows={5} value={PHONE} disabled/>
                                </div>
                            </div>

                            <header>
                                <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                                    Ticket Info
                                </h2>
                            </header>

                            <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-25--md"/>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Subject</label>
                                <div className="form-group mb-0">
                                    <input type="text" id="bio"
                                           className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                           rows={5} value={SUBJECT} disabled/>
                                </div>
                            </div>

                            <div className="g-mb-20">
                                <label className="g-mb-10" htmlFor="#bio">Message</label>
                                <div className="form-group mb-0">
                                    <textarea
                                        className="form-control form-control-md g-brd-gray-light-v7 g-brd-lightblue-v3--focus g-rounded-4 g-px-20 g-py-12"
                                        rows={5} value={MESSAGE} disabled/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TicketScreen));