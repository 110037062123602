import React, {Component} from 'react';

class Card extends Component {
    render() {
        return (
            <div className="col-sm-6 col-lg-6 col-xl-3 g-mb-30">
                <div className="card h-100 g-brd-gray-light-v7 g-rounded-3">
                    <div className="card-block g-font-weight-300 g-pa-20">
                        <div className="media">
                            <div className="d-flex g-mr-15">
                                <div
                                    className="u-header-dropdown-icon-v1 g-pos-rel g-width-60 g-height-60 g-bg-lightblue-v4 g-font-size-18 g-font-size-24--md g-color-white rounded-circle">
                                    <i className={"g-absolute-centered " + this.props.icon}/>
                                </div>
                            </div>
                            <div className="media-body align-self-center">
                                <div className="d-flex align-items-center g-mb-5">
                                    <span className="g-font-size-24 g-line-height-1 g-color-black">
                                        {this.props.value}
                                    </span>
                                </div>
                                <h6 className="g-font-size-16 g-font-weight-300 g-color-gray-dark-v6 mb-0">
                                    {this.props.name}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;