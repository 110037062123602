import React, {Component} from 'react'
import FormItem from "../../../modules/FormItem";
import FormTextBox from "../../../modules/FormTextBox";
import {COLORS, request} from "../../../util/Util";
import {addNotification, ALERT_ERROR, ALERT_INFO} from "../../../util/NotificationUtil";

class NewsTab extends Component {
    state = {
        post: null
    };

    componentWillMount() {
        const {ID} = this.props.match.params;
        let post = this.props.partner.news.find((item) => item.ID === parseInt(ID));

        if (typeof post !== "undefined") {
            this.setState({post: JSON.parse(JSON.stringify(post))});
        }
    }

    save() {
        const {ID} = this.props.match.params;
        let {post} = this.state;

        request("admin/news/" + ID, "PATCH", post).then(() => {
            this.props.updatePost(post);

            addNotification.call(this, "Post Saved", "Your post has been saved", ALERT_INFO);
        }).catch(() => {
            addNotification.call(this, "Error Saving Post", "There was an internal error saving your news post", ALERT_ERROR);
        })
    }

    render() {
        let {post} = this.state;

        if (post === null) {
            return <div/>;
        }

        return (
            <div className="col-md-9">
                <div className="h-100 g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md">
                    <header>
                        <h2 className="text-uppercase g-font-size-12 g-font-size-default--md g-color-black mb-0">
                            General information

                            <a style={{color: COLORS.DRIP_GOLD, cursor: "pointer", fontWeight: "bold", float: "right"}}
                               onClick={this.save.bind(this)}>
                                Save
                            </a>
                        </h2>
                    </header>

                    <hr className="d-flex g-brd-gray-light-v7 g-my-15 g-my-30--md"/>

                    <FormItem name="Title" value={post.TITLE}
                              onChange={(txt) => this.setState({post: {...post, TITLE: txt}})}/>
                    <FormTextBox rows={2} name="Subtitle" value={post.SUBTITLE}
                                 onChange={(txt) => this.setState({post: {...post, SUBTITLE: txt}})}/>
                    <FormTextBox rows={8} name="Content" value={post.CONTENT}
                                 onChange={(txt) => this.setState({post: {...post, CONTENT: txt}})}/>
                </div>
            </div>
        )
    }
}

export default NewsTab;