import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Header extends Component {
    componentDidMount() {
        document.getElementById("partner_logout").onclick = () => {
            if (!window.confirm("Are you sure you want to logout")) {
                return;
            }

            this.props.history.push("/login");
        };

        eval(`$.HSCore.components.HSDropdown.init($('[data-dropdown-target]'), {
                dropdownHideOnScroll: false,
                dropdownType: 'css-animation',
                dropdownAnimationIn: 'fadeIn',
                dropdownAnimationOut: 'fadeOut'
            });`);

        eval(`$.HSCore.components.HSSideNav.init('.js-side-nav', {
                afterOpen: function () {
                    setTimeout(function () {
                        $.HSCore.components.HSAreaChart.init('.js-area-chart');
                        $.HSCore.components.HSDonutChart.init('.js-donut-chart');
                        $.HSCore.components.HSBarChart.init('.js-bar-chart');
                    }, 400);
                },
                afterClose: function () {
                    setTimeout(function () {
                        $.HSCore.components.HSAreaChart.init('.js-area-chart');
                        $.HSCore.components.HSDonutChart.init('.js-donut-chart');
                        $.HSCore.components.HSBarChart.init('.js-bar-chart');
                    }, 400);
                }
            });`);

        eval(`$.HSCore.helpers.HSHamburgers.init('.hamburger')`);
    }

    render() {
        let Swap = (
            <div className="g-pos-rel g-px-10--lg">
                {/* Top User Menu */}
                <ul id="profileMenu2"
                    className="g-pos-abs g-left-0 g-width-100x--lg g-nowrap g-font-size-14 g-py-20 g-mt-17 rounded"
                    aria-labelledby="switchInvoker">
                    <li className="g-hidden-sm-up g-mb-10">
                        <a className="media g-py-5 g-px-20" href="#!">
                                                <span className="d-flex align-self-center g-pos-rel g-mr-12">
                                                    <span
                                                        className="u-badge-v1 g-top-minus-3 g-right-minus-3 g-width-18 g-height-18 g-bg-lightblue-v5 g-font-size-10 g-color-white rounded-circle p-0">10</span>
                                                    <i className="hs-admin-comment-alt"/>
                                                </span>
                            <span
                                className="media-body align-self-center">Unread Messages</span>
                        </a>
                    </li>
                    <li className="g-hidden-sm-up g-mb-10">
                        <a className="media g-py-5 g-px-20" href="#!">
                                                <span className="d-flex align-self-center g-mr-12">
                                                    <i className="hs-admin-bell"/>
                                                </span>
                            <span className="media-body align-self-center">Notifications</span>
                        </a>
                    </li>
                </ul>
            </div>
        );


        return (
            <header id="js-header" className="u-header u-header--sticky-top">
                <div className="u-header__section u-header__section--admin-dark g-min-height-65">
                    <nav className="navbar no-gutters g-pa-0">
                        <div className="col-auto d-flex flex-nowrap u-header-logo-toggler g-py-12">
                            {/* Logo */}
                            <div style={{alignItems: "center"}}>
                                <Link to="/"
                                      className="navbar-brand d-flex align-self-center g-hidden-xs-down g-line-height-1 py-0 g-mt-5">
                                </Link>
                            </div>
                            {/* End Logo */}
                            {/* Sidebar Toggler */}
                            <a className="js-side-nav u-header__nav-toggler d-flex align-self-center ml-auto"
                               href="#!"
                               data-hssm-class="u-side-nav--mini u-sidebar-navigation-v1--mini"
                               data-hssm-body-class="u-side-nav-mini"
                               data-hssm-is-close-all-except-this="true"
                               data-hssm-target="#sideNav">
                                <i className="hs-admin-align-left"/>
                            </a>
                            {/* End Sidebar Toggler */}
                        </div>
                        {/* Messages/Notifications/Top Search Bar/Top User */}
                        <div className="col-auto d-flex g-py-12 g-pl-40--lg ml-auto">
                            <div className="col-auto d-flex g-pt-5 g-pt-0--sm g-pl-10 g-pl-20--sm">
                                {/* End Top Notifications */}
                                {/* Top User */}
                                {Swap}
                                {/* End Top User Menu */}
                                {/* Top User */}
                                <div className="g-pos-rel g-px-10--lg">
                                    <a id="profileMenuInvoker" className="d-block" href="#!"
                                       aria-controls="profileMenu" aria-haspopup="true"
                                       aria-expanded="false"
                                       data-dropdown-event="click" data-dropdown-target="#profileMenu"
                                       data-dropdown-type="css-animation" data-dropdown-duration={300}
                                       data-dropdown-animation-in="fadeIn"
                                       data-dropdown-animation-out="fadeOut">
                                        <span className="g-pos-rel">
                                            <span
                                                className="u-badge-v2--xs u-badge--top-right g-hidden-sm-up g-bg-lightblue-v5 g-mr-5"/>
                                            <img width={30} height={30}
                                                 className="g-width-30 g-width-40--md g-height-30 g-height-40--md rounded-circle g-mr-10--sm"
                                                 src={""} alt="Image description"/>
                                        </span>
                                        <span className="g-pos-rel g-top-2">
                                          <span className="g-hidden-sm-down">Admin Menu</span>
                                          <i className="hs-admin-angle-down g-pos-rel g-top-2 g-ml-10"/>
                                        </span>
                                    </a>
                                    {/* Top User Menu */}
                                    <ul id="profileMenu"
                                        className="g-pos-abs g-left-0 g-width-100x--lg g-nowrap g-font-size-14 g-py-20 g-mt-17 rounded"
                                        aria-labelledby="profileMenuInvoker">
                                        <li className="g-hidden-sm-up g-mb-10">
                                            <a className="media g-py-5 g-px-20" href="#!">
                                                <span className="d-flex align-self-center g-pos-rel g-mr-12">
                                                    <span
                                                        className="u-badge-v1 g-top-minus-3 g-right-minus-3 g-width-18 g-height-18 g-bg-lightblue-v5 g-font-size-10 g-color-white rounded-circle p-0">10</span>
                                                    <i className="hs-admin-comment-alt"/>
                                                </span>
                                                <span
                                                    className="media-body align-self-center">Unread Messages</span>
                                            </a>
                                        </li>
                                        <li className="mb-0">
                                            <a
                                                className="media g-color-lightred-v2--hover g-py-5 g-px-20"
                                                href="#" id="partner_logout">
                                                    <span className="d-flex align-self-center g-mr-12">
                                                      <i className="hs-admin-shift-right"/>
                                                    </span>
                                                <span className="media-body align-self-center">Sign Out</span>
                                            </a>
                                        </li>
                                    </ul>
                                    {/* End Top User Menu */}
                                </div>
                            </div>
                            {/* End Top User */}
                        </div>
                        {/* End Messages/Notifications/Top Search Bar/Top User */}
                    </nav>
                </div>
            </header>
        );
    }
}

export default Header;